import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getValorCusto } from '../../../api/chamados/get/produtos'

const CompraContext = createContext();

export const CompraProvider = ({ children }) => {
  const cart = useSelector(state => state.cart.cart);
  const [compra, setCompra] = useState([]);

  const atualizarItemCompra = async (item) => {
    const itemExistente = compra.find((compraItem) => compraItem.id === item.id);

    let novoItem = {
      id: item.id,
      quantidade: item.quantity,
      totalOpcionais: item.totalOP,
      observacao: item.observacoes,
      totalitempedido: item.total,
      sabores: [],
      mista: item.mista,
      retirados: item?.retirados ? item.retirados : [],
      opcionais: item.opcionais?.map(opcional => ({
        id: opcional.ID,
        descricao: opcional.DESCRICAO,
        valor: opcional.VALOR_VENDA,
        quantidade: opcional.quantidade,
        imprimir: opcional.IMPRIMIR,
        descricaogrupo: opcional.descricaogrupo,
        grupoUnicoId: opcional.grupoUnicoId
      })),
    };

    if (item.mista === "NAO") {
      const custo = await getValorCusto(item.produto.ID_PRODUTO, item.tamanhoProduto?.ID_GRADE || 0);
      novoItem = {
        ...novoItem,
        idproduto: item.produto.ID_PRODUTO,
        valor: item.produto.VALOR_VENDA,
        idgrade: item.tamanhoProduto?.ID_GRADE,
        idtamanho: item.tamanhoProduto?.ID,
        tamanhodesc: item.tamanhoProduto?.TAMANHO,
        imprimir: item.produto.IMPRIMIR,
        descricao: item.produto.PRODUTO,
        custoitemPedido: custo + item.opcionais.reduce((acc, item) => acc + (item.VALOR_CUSTO * item.quantidade), 0),
      };
    } else if (item.mista === "SIM") {
      const saboresWithCusto = await Promise.all(item.sabores?.map(async (sabor) => {
        const custo = await getValorCusto(sabor.ID, sabor.ID_GRADE || 0);
        return {
          id: sabor.ID,
          sabor: sabor.PRODUTO,
          valor: sabor.VALOR_VENDA,
          quantidade: sabor.quantidade,
          idgradeSabor: sabor.ID_GRADE
        };
      }) || []);
      novoItem = {
        ...novoItem,
        idproduto: item.tamanhoPizza.ID_PRODUTO,
        idtamanho: item.tamanhoPizza?.ID,
        sabores: saboresWithCusto,
        totalPizza: item.totalPizza,
        descricao: item.tamanhoPizza.TAMANHO,
        custoitemPedido: saboresWithCusto.reduce((acc, item) => acc + (item.custo * item.quantidade), 0),
      };
    }
    setCompra((compraAtual) => {
      if (itemExistente) {
        return compraAtual.map(compraItem => compraItem.id === item.id ? novoItem : compraItem);
      } else {
        return [...compraAtual, novoItem];
      }
    });
  };

  useEffect(() => {
    cart.forEach(item => atualizarItemCompra(item));
  }, [cart]);

  return (
    <CompraContext.Provider value={{ compra }}>
      {children}
    </CompraContext.Provider>
  );
};

export const useCompra = () => {
  return useContext(CompraContext);
};
