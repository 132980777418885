import React, { useEffect, useRef, useState } from "react";
import { getFichaTecnica, getGrupoOpcionais, listaTamanhosProduto } from "../../api/chamados/get/produtos";
import { useTranslation } from "react-i18next";
import { removeItem, updateCartItem } from '../../redux/cartSlice';
import { useDispatch, useSelector } from "react-redux";
import { JanelaMensagem } from "../../components/funcoes/funcoes";
import Tamanhos from '../../components/tamanhos/tamanhos';
import Observacoes from "../../components/observacoes";
import Opcionais from "./opcionais";
import Retirados from './fichaTecnica'
import Decimal from 'decimal.js'
import { useCart } from '../../components/cartContext'
import { AnimatePresence, motion } from 'framer-motion';
import { forceRehydrate } from "../../redux/store";

export default function ModalOpcionais({ modalOpcionais, setModalOpcionais, cartmodal, itemcart }) {
    const cart = useSelector((state) => state.cart.cart);
    const item = cartmodal ? cart.find(item => item.id === itemcart.id) : cart?.[cart.length - 1];
    const [currentPage, setCurrentPage] = useState('loading');
    const [tamanhoEscolhido, setTamanhoEscolhido] = useState(item?.tamanhoProduto || '');
    const [etapas, setEtapas] = useState([]);
    const [isClosing, setIsClosing] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        verificarTelas();
    }, []);
    
    const verificarTelas = async () => {
        const possuiTamanho = item?.mista === 'SIM' ? false : ((await listaTamanhosProduto(item.produto.ID_PRODUTO)).length > 0 ? true : false)
        const possuiOpcional = await getGrupoOpcionais(item?.produto.ID_PRODUTO, tamanhoEscolhido?.ID || 0, false);
        const possuiRetirados = await getFichaTecnica(item?.produto.ID_PRODUTO, tamanhoEscolhido?.ID || 0);
        
        const novasEtapas = [   
            possuiTamanho && 'tamanho',
            possuiOpcional?.length > 0 && 'opcionais',
            possuiRetirados?.length > 0 && 'retirados',
            'observacoes'
        ].filter(Boolean);
    
        setEtapas(novasEtapas);
        navegarParaProximaEtapa(novasEtapas, 0);
    };
    
    const navegarParaProximaEtapa = (etapas, index) => {
        if (index < etapas.length) {
            setCurrentPage(etapas[index]);
        } 
    };

    const handleCancelar = () => {
        setIsClosing(true); // Ativa o estado de fechamento
        if(cartmodal){
            return
        } else {
            dispatch(removeItem(item.id));
        }
        setTimeout(() => {
            setModalOpcionais(false); // Fecha o modal após a animação de saída            
        }, 600); // Duração igual ao tempo da animação de saída
    };

    React.useEffect(() => {
        document.body.style.overflow = modalOpcionais ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalOpcionais]);
    
    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    if (!modalOpcionais && !isClosing) return null;

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return (
        <AnimatePresence>
            {!isClosing && modalOpcionais && (
                <>
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={overlayVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    />
                    <motion.div
                        className="modal-container"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    >
                        <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black`} style={{ width: '97%' }}>
                            {currentPage === 'tamanho' && (
                                <TamanhoStep
                                    tamanhoEscolhido={tamanhoEscolhido}
                                    setTamanhoEscolhido={setTamanhoEscolhido}
                                    onCancel={handleCancelar}
                                    item={item}
                                    setCurrentPage={() => navegarParaProximaEtapa(etapas, 1)}
                                />
                            )}
                            {currentPage === 'opcionais' && (
                                <OpcionaisStep
                                    onCancel={handleCancelar}
                                    item={item}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                            {currentPage === 'retirados' && (
                                <RetiradosPage
                                    onCancel={handleCancelar}
                                    item={item}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                            {currentPage === 'observacoes' && (
                                <ObservacaoStep
                                    onCancel={handleCancelar}
                                    item={item}
                                    setModalOpcionais={setModalOpcionais}
                                    setIsClosing={setIsClosing}
                                />
                            )}
                            {currentPage === 'loading' && (
                                <div className='grid place-items-center'>
                                    
                                </div>
                            )}
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
}

function TamanhoStep({ tamanhoEscolhido, setTamanhoEscolhido, onCancel, item, setCurrentPage }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (item.tamanhoProduto) {
            setTamanhoEscolhido(item.tamanhoProduto);
        }
    }, [item.tamanhoProduto, setTamanhoEscolhido]);

    const addconfirmar = async () => {
        if (!tamanhoEscolhido) {
            JanelaMensagem(t('escolhatamanho'), 'amarelo');
            return;
        }    
        const updatedItem = {
            ...item,
            tamanhoProduto: tamanhoEscolhido,
            total: new Decimal(item.total)
                .plus(new Decimal(tamanhoEscolhido.VALOR_VENDA))
                .toFixed(2) 
        };        
        dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));    
        try {
            setCurrentPage();
        } catch (error) {
            console.error('Error checking for optional add-ons:', error);
        }
    };

    return (
        <div>
            <div className="grid place-items-center p-2 text-xl" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> {t('tamanho').toUpperCase()} </div>
                <Tamanhos produto={item.produto} tamanhoEscolhido={tamanhoEscolhido} setTamanhoEscolhido={setTamanhoEscolhido} />
            <div className="flex justify-between">
                <button className="p-3 m-2 bg-red-500 text-white rounded-lg" onClick={onCancel}> {t('cancelar')} </button>
                <button className="p-3 m-2 bg-black text-white rounded dark:bg-white dark:text-black" onClick={addconfirmar}> {t('confirmar')} </button>
            </div>
        </div>
    );
}

function OpcionaisStep({ onCancel, item, setCurrentPage }) {
    const { t } = useTranslation();    
    const [grupos, setGrupos] = useState([]);
    const [newListSelect, setNewListSelect] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(item.opcionais.length > 0){
            setNewListSelect(item.opcionais)
        }
    }, [item])

    const handleFinalizar = async () => {
        const updatedOpcionais = [...newListSelect];
        const newTotalOP = newListSelect.reduce((sum, data) => {
            return sum.plus(new Decimal(data.VALOR_VENDA).times(data.quantidade));
        }, new Decimal(0));
        const updatedItem = {
            ...item,
            opcionais: updatedOpcionais,
            descricaogupo: item.descricaogrupo,
            total: new Decimal(item.total).plus(newTotalOP).toFixed(2),
            totalOP: newTotalOP.toFixed(2)
        };
        await dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));
        await forceRehydrate()
        try {
            const possuiRetirados = await getFichaTecnica(item.produto.ID_PRODUTO, item.tamanhoEscolhido?.ID || 0);
            if (possuiRetirados.length > 0) { 
                setCurrentPage('retirados');
            } else {
                setCurrentPage('observacoes');
            }
        } catch (error) {
            console.error('Error checking for retirados:', error);
        }
    }

    const handleNextGroup = () => {
        if (currentIndex < grupos.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleBackGroup = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <>
            
            <Opcionais item={item} grupos={grupos} setGrupos={setGrupos} currentIndex={currentIndex} newListSelect={newListSelect} setNewListSelect={setNewListSelect}/>
            <div className="p-2 flex place-items-center justify-between flex-wrap">
                {
                    currentIndex > 0 ? (
                        <button className="p-3 m-2 bg-blue-400 text-white rounded-lg w-[120px]" onClick={handleBackGroup}> {t('voltar').toUpperCase()} </button>
                    ) : null
                }
                    <button className="p-3 m-1 bg-red-500 text-white rounded-lg w-[120px]" onClick={onCancel}> {t('cancelar').toUpperCase()} </button>
                {
                    currentIndex === grupos.length -1 ? (
                        <button className="p-3 m-1 bg-black text-white dark:bg-white dark:text-black rounded-lg w-[120px]" onClick={handleFinalizar}> {t('addnocart').toUpperCase()} </button>
                    ) : (
                        <button className="p-3 m-2 bg-blue-400 text-white rounded-lg w-[120px]" onClick={handleNextGroup}> {t('proximo').toUpperCase()} </button>
                    )
                }
            </div>
        </>
    );
}

function RetiradosPage({ onCancel, item, setCurrentPage }){
    const [retirados, setRetirados] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const addRetirados=  ()=>{
        const updatedItem = {
            ...item, 
            retirados: retirados
        }
        dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }))
        setCurrentPage('observacoes')
    }

    return(
        <>
            <Retirados
                item={item}
                retirados={retirados}
                setRetirados={setRetirados}
            />
            <div className="p-2 flex place-items-center justify-between">
                <button className="p-3 m-2 bg-red-500 text-white rounded-lg" onClick={onCancel}> {t('cancelar')} </button>
                <button className="p-3 m-2 rounded-lg bg-black text-white dark:bg-white dark:text-black" onClick={addRetirados}> {t('addnocart')} </button>
            </div>
        </>
    )
}

function ObservacaoStep({ onCancel, item, setModalOpcionais, setIsClosing }) {
    const { t } = useTranslation();
    const [observacao, setObservacao] = useState('');
    const dispatch = useDispatch();
    const { cartanimationAdd } = useCart();
    
    const addobs = () => { 
        const updatedItem = {
            ...item,
            observacoes: observacao 
        };
        dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));      
        setIsClosing(true); // Ativa o estado de fechamento
        setTimeout(() => {
            setModalOpcionais(false); // Fecha o modal após a animação de saída
        }, 600); // Duração igual ao tempo da animação de saída
        cartanimationAdd(item)
    };  
    
    return (
        <div>
            <div className="grid place-items-center text-xl p-2 rounded-t" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> {t('observacao')} </div>
            <div className="h-[200px]">
                <Observacoes setObservacao={setObservacao} />        
            </div>
            <div className="flex justify-between"> 
                <button className="p-3 m-2 bg-red-500 text-white rounded-lg" onClick={onCancel}> {t('cancelarproduto').toUpperCase()} </button>
                <button className="p-3 m-2 bg-black rounded-lg text-white dark:bg-white dark:text-black" onClick={addobs}> {t('addnocart').toUpperCase()} </button>
            </div>
        </div>
    );
}