import { useTranslation } from "react-i18next";
import Header from "../components/header/Header";
import MainCardapio from './cardapio/produtosPorLista'
import { JanelaMensagem } from "../components/funcoes/funcoes";
import { inserirServico } from "../api/chamados/post/pedidos";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from 'framer-motion';

export default function Main() {
  const { t } = useTranslation();
  const [modalServico, setModalServico] = useState(false);
  const [ativo, setAtivo] = useState(false);

  useEffect(()=>{
    const res = localStorage.getItem('cardapiosimples') === "SIM" ? false : true
      setAtivo(res)
  }, [])

  const handleSolicitarServico= async ()=>{
    setModalServico(true)
  }

  return ( 
    <div>
      <div>
        <Header/>
        <div>
          <MainCardapio/>
        </div>
          {
            ativo && (
                <button className="fixed text-white bottom-20 right-2 grid place-items-center h-[75px] w-[75px]" style={{ borderRadius: '50%', background: 'rgb(193,164,164)', background: 'rgb(86,7,12)' }} onClick={handleSolicitarServico}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="45" height="45" viewBox="0 0 530 500" preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
                      <path d="M2775 4566 c-178 -36 -319 -111 -444 -239 -104 -106 -173 -223 -213 -364 -33 -114 -33 -333 0 -448 110 -381 472 -637 862 -612 155 10 281 53 416 143 169 112 290 283 345 489 30 112 30 297 0 410 -45 171 -151 337 -283 444 -82 67 -218 137 -315 162 -102 26 -274 33 -368 15z"/>
                      <path d="M1083 2910 c-24 -10 -53 -58 -53 -90 0 -14 6 -20 21 -20 19 0 20 -4 15 -62 -3 -35 -11 -148 -17 -253 -9 -146 -15 -192 -26 -198 -41 -24 -85 -74 -110 -125 l-28 -57 -3 -634 c-2 -573 -1 -636 14 -652 15 -17 9 -18 -156 -21 l-172 -3 -24 -28 c-35 -41 -40 -75 -18 -116 10 -20 30 -41 44 -48 20 -10 302 -13 1333 -13 1409 0 1349 -2 1377 49 13 25 13 87 0 112 -26 48 -34 49 -584 49 l-515 0 -24 24 c-16 16 -41 27 -73 31 -99 13 -94 3 -94 184 l0 159 43 10 c60 16 147 71 174 111 27 39 27 36 5 291 l-17 195 -252 3 c-139 1 -253 -1 -253 -5 0 -5 -7 -105 -16 -224 -15 -200 -15 -219 1 -248 22 -43 91 -91 168 -117 l62 -21 3 -151 c3 -174 6 -169 -99 -191 -31 -7 -65 -21 -74 -31 -16 -18 -32 -20 -129 -20 -85 0 -107 3 -98 12 9 9 12 166 12 643 0 734 5 692 -95 791 l-61 60 -12 215 c-16 286 -17 279 9 279 18 0 20 4 14 33 -4 17 -20 44 -36 60 -28 26 -33 27 -132 26 -56 0 -112 -4 -124 -9z m1051 -1202 c3 -13 6 -47 6 -75 l0 -53 -190 0 -190 0 0 75 0 75 184 0 c182 0 184 0 190 -22z"/>
                      <path d="M2567 2773 c-4 -3 -7 -116 -7 -250 0 -201 2 -245 14 -249 8 -3 91 40 184 95 l169 101 172 -101 c100 -59 177 -99 186 -95 13 5 15 40 15 233 0 227 -4 273 -22 273 -5 0 -86 -45 -180 -100 l-170 -101 -173 101 c-186 107 -178 103 -188 93z"/>
                      <path d="M2274 2595 c-192 -52 -385 -130 -577 -235 -161 -87 -151 -75 -116 -146 34 -70 49 -141 49 -235 l0 -69 325 0 c252 0 327 -3 330 -12 2 -7 14 -128 25 -269 26 -312 26 -317 -63 -405 -36 -34 -82 -68 -109 -80 l-48 -19 0 -57 0 -58 350 0 c273 0 350 3 350 13 0 6 -90 367 -198 802 -143 571 -202 791 -213 792 -8 1 -55 -9 -105 -22z"/>
                      <path d="M3266 1834 c-108 -434 -199 -797 -202 -806 -5 -17 36 -18 770 -18 l776 0 0 423 c0 446 -4 501 -45 594 -64 143 -315 319 -650 456 -116 48 -350 123 -414 133 l-38 6 -197 -788z"/>
                      <path d="M2872 2137 c-28 -29 -28 -67 -1 -101 34 -43 99 -35 125 16 27 53 -6 108 -66 108 -25 0 -43 -7 -58 -23z"/>
                      <path d="M2875 1825 c-70 -69 25 -177 100 -113 38 34 39 82 1 115 -35 30 -71 29 -101 -2z"/>
                      <path d="M2872 1507 c-28 -29 -28 -67 -1 -101 34 -43 99 -35 125 16 27 53 -6 108 -66 108 -25 0 -43 -7 -58 -23z"/>
                      </g>
                    </svg>
                  </div>
                  <div style={{ fontSize: 12, marginTop: '-10px' }}> {t('chamar').toUpperCase()} </div>
                </button>
            )
          }        
      </div>
      { modalServico && <ModalServico setModalServico={setModalServico} /> }
    </div>
  );
}

function ModalServico({ setModalServico }){
  const tipoComanda = localStorage.getItem('tipoComanda')
  const numeroComanda = localStorage.getItem('numeroComanda')
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };
    
  const modalVariants = {
      hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
      visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
      exit: { 
          opacity: 0, 
          scale: 0.4, 
          borderRadius: "50%", 
          transition: { duration: 0.4, ease: "easeOut" }
      }
  };

  const handleserv= async ()=>{
    const dados = {
      numerocomanda: numeroComanda,
      tipocomanda: tipoComanda,
      tipo: 'GERAL'
    }
    if(tipoComanda && numeroComanda){
      const res = await inserirServico(dados)
      if(res === 'ok'){
          const mensagem = t('garcomchamado')
          JanelaMensagem(mensagem, 'verde')
          fechar()
      }
    }
  }

  const fechar=()=>{
    setIsClosing(true); // Ativa o estado de fechamento
    setTimeout(() => {
      setModalServico(false); // Fecha o modal após a animação de saída
    }, 600);
  }

  const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

  return(
    <AnimatePresence>
      {!isClosing && (
        <>
          <motion.div
            className="overlay"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            transition={{ duration: 0.4, ease: 'easeOut' }}
          />
          <motion.div
            className="modal-container"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{ duration: 0.4, ease: 'easeOut' }}
          >
            <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black`} style={{ width: '97%' }}>
              <div className="grid place-items-center p-5 text-xl"> {t('confirmargarcom')} </div>      
              <div className="flex place-items-center justify-between m-3">
                <button className="p-3 rounded bg-red-500 text-white" onClick={fechar}> {t('cancelar')} </button>
                <button className="p-3 rounded bg-black text-white dark:bg-white dark:text-black" onClick={handleserv}> {t('chamar')} </button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}
