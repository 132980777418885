import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../../redux/cartSlice';
import { JanelaMensagem, formatarValor } from '../../../components/funcoes/funcoes'
import { inserirPedido } from '../../../api/chamados/post/pedidos'
import { t } from 'i18next'
import { getValorCusto } from '../../../api/chamados/get/produtos';
import Modalpedirdelivery from './modalpedirdelivery'
import { getstatusmesa } from '../../../api/chamados/get/pedidos'
import { useCompra } from './compracontext';

export function CarrinhoBarPagamento({ observacoesCart }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [totalCart, setTotalCart] = useState('');
  const [modalpedirdelivery, setModalpedirdelivery] = useState('');
  const numeroComanda = localStorage.getItem('numeroComanda');
  const tipoComanda = localStorage.getItem('tipoComanda')
  const cart = useSelector(state => state.cart.cart)
  const { compra } = useCompra();
  
    useEffect(() => {
      if (cart && Array.isArray(cart)) {
        let total = new Decimal(0);
        cart.forEach(item => {
          total = total.plus(new Decimal(item.total || 0).times(item.quantity || 1));
        });
        setTotalCart(total.toFixed(2));
      }
    }, [cart]);

    const chamadaPedido= async ()=>{
      const cor = 'amarelo'
      if (cart.length === 0){
        const mensagem = t('adicionePedido')
          JanelaMensagem(mensagem, cor)
      } else {
        if(tipoComanda !== null && tipoComanda !== '' && tipoComanda !== 'null' && numeroComanda !== null && numeroComanda !== '' && numeroComanda !== 'null'){
          const status = await getstatusmesa(numeroComanda)
          if(status === 'OCUPADA' || status === 'LIBERADA'){
            EnviarPedidoAPI()
          } else {
            JanelaMensagem(t('mesabloqueada'), 'amarelo')
          }
        } else {
          setModalpedirdelivery(true)
        }
      }
    }
    const EnviarPedidoAPI = async ()=>{
        const dados = {
          tokenNotificacao: '',
          pagamentoAntecipado: "NÃO",
          numerocomanda: numeroComanda,
          tipocomanda: tipoComanda,
          total: totalCart,
          observacoesPedido: observacoesCart,
          itemspedido: compra,
          app: 'CARDAPIO',
          localizacao: ''
        }
        const result = await inserirPedido(dados)
          if(result > 0){
            JanelaMensagem(t('pedidofeito'), 'verde')
            dispatch(clearCart())
            navigate('/Main')
          }        
    }
    const handleCotinuar = () => {
      navigate('/Main');
    };

  return (
    <div>
      <div className='caixaBarPagar' >
        {
          cart.length > 0 ? (
            <button className='cartBarPagar bg-black dark:bg-white h-20 text-white' onClick={()=> chamadaPedido()}> 
                <div className='PagarTexto font-medium'> {t('confirmar').toUpperCase()} </div>
              <div className='pagarValor font-medium'> {formatarValor(totalCart)} </div>
            </button>
          ) : null
        }
      </div>
        <div className='cartBarContinuar bg-orange-600 text-white h-14 font-medium' onClick={handleCotinuar}> {t('continuarComprando').toUpperCase()} </div>
        { modalpedirdelivery && <Modalpedirdelivery modalpedirdelivery={modalpedirdelivery} setModalpedirdelivery={setModalpedirdelivery} compra={compra} observacoesCart={observacoesCart} />}
    </div>
  );
}