import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from "react-icons/ai";
import { FaOpencart } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { MdAttachMoney } from "react-icons/md";
import { useCart } from '../../components/cartContext';

export default function BarraCarrinhoAtalho({ ativo }) {
  const [pagina, setPagina] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart.cart);
  const { cartRef, setRef } = useCart();
  const shoppingCartRef = useRef(null);
    
  useEffect(() => {
    setRef(shoppingCartRef.current);
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const nome = path.substring(path.lastIndexOf('/') + 1);
    setPagina(nome);
  }, [location.pathname]);

  return (
    <>
        <div className='fixed bottom-0 w-full py-1 border-t grid grid-cols-3 z-50' style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}>
          <div className='grid place-items-center col-start-1'>
            <Link to='/pedidos' style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div className='grid place-items-center'>
                  <MdAttachMoney size={30} color={pagina === 'pedidos' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')} />
                </div>
              <div style={{ color: pagina === 'pedidos' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado') }}> 
                {
                  ativo ? t('minhamesa').toUpperCase() : t('pedidos').toUpperCase()
                }
              </div>
            </Link>
          </div>
          <div className='grid place-items-center col-start-2'>
            <Link to='/Main' style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <div className='grid place-items-center'>
                <AiFillHome size={30} color={pagina === 'Main' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')} />
              </div>
              <div style={{ color: pagina === 'Main' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado') }} > {t('menu').toUpperCase()} </div>
            </Link>
          </div>
          <div className='grid place-items-center col-start-3 relative shopping-cart' ref={shoppingCartRef}>
            <Link to='/Carrinho' style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <div className='flex place-items-center '>
                <div className='w-3/4 grid place-items-center'>
                  <FaOpencart size={30} color={pagina === 'Carrinho' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')} />
                </div>
                {cart.length > 0 ? (
                  <div className="h-7 w-7 grid place-items-center" style={{ borderRadius: '50%', background: localStorage.getItem('corvalor') }}>
                    <div className='text-white'>{cart.length}</div>
                  </div>
                ) : null}
              </div>
              <div style={{ color: pagina === 'Carrinho' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado') }} > {t('carrinho').toUpperCase()} </div>
            </Link>
          </div>
        </div>
    </>
  );
}
