import { conexao } from "../../conexao";
import { JanelaMensagem } from "../../../components/funcoes/funcoes";


export const getdadospix = async ( ) =>{
    try {
        const dadospix = {
            ID: 2, 
            ID_CONTA_BANCO: 2,
            CHAVE_PIX: '',
            PSPATUAL: 'Itau',
            CLIENTE_ID: 'dc79e63e-3edf-4c06-b14f-a86b82849353',
            CLIENT_SECRET: '76d59643-9a14-48e1-8e6b-f47816b92c9b',
            CHAVE_PRIVADA: '',
            CERTIFICADO: '',
            ATIVO: 'SIM',
            APP_WEB: 'SIM'
        }
        const getdata = await conexao.get(`/dadospix`)        
        const dados = Array.isArray(getdata?.data) ? getdata?.data[0] : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const gerarPixQR = async ( dadospix, valor, modo, tipoComanda, numeroComanda, qtddivisao ) =>{
    try {
        const dados = {
            dadospix: dadospix,
            valor: valor.replace(',', '.'),
            modo: modo,
            tipocomanda: tipoComanda,
            numerocomanda: numeroComanda,
            qtddivisao: qtddivisao
        }
        const getdata = await conexao.post(`/gerarPix`, dados)
            return getdata.data;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const consultarPix = async ( dadospix, txid, modo ) =>{
    try {
        const body = {
            dadospix: dadospix,
            txid: txid,
            modo: modo
        }
        const getdata = await conexao.post(`/consultarPix`, body)
        const dados = getdata?.data
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const cancelarpix = async ( dadospix, txid, modo ) =>{
    try {
        const getdata = await conexao.post(`/cancelarpix`, {
            dadospix: dadospix,
            txid: txid,
            modo: modo
        })
        const dados = getdata?.data
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const inserirpago = async ( dadospix, valor, modo, tipoComanda, numeroComanda, qtddivisao ) =>{
    try {
        const getdata = await conexao.post(`/inserirpago`, {
            dadospix: dadospix,
            valor: valor.replace(',', '.'),
            modo: modo,
            tipocomanda: tipoComanda,
            numerocomanda: numeroComanda,
            qtddivisao: qtddivisao
        })
        const dados = getdata?.data
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}