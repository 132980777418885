import { Route, Routes } from "react-router-dom";
import TelaInicialCardapio from './TelaInicialCardapio'
import Main from './pages/main'
import Pedidos from "./pages/pedidos/pedidos";
import Carrinho from './pages/carrinho/Carrinho'
import './style.css'

export default function App(){
  

  return(
          <Routes>
            <Route path='/' element={<TelaInicialCardapio/>}/>
            <Route path='/Main' element={ <Main/> } />
            <Route path='/Carrinho' element={<Carrinho/>}/>
            <Route path='/Pedidos' element={<Pedidos/>}/>
          </Routes>
    )
}



