import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { cartReducer } from "./cartSlice";
import pedidosReducer from './pedidosSlice';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// Configuração do redux-persist para o cart
const cartPersistConfig = {
  key: 'cart',
  storage,
};

// Configuração do redux-persist para pedidos
const pedidosPersistConfig = {
  key: 'pedidos',
  storage,
};

// Aplica a persistência aos reducers individualmente
const persistedCartReducer = persistReducer(cartPersistConfig, cartReducer);
const persistedPedidosReducer = persistReducer(pedidosPersistConfig, pedidosReducer);

// Combina os reducers em um único rootReducer
const rootReducer = combineReducers({
  cart: persistedCartReducer,
  pedidos: persistedPedidosReducer,
});

// Configuração da store com o rootReducer
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Cria o persistor para persistir a store
export const persistor = persistStore(store);
export const forceRehydrate = () => {
  persistor.flush();
};
