import { useState } from 'react'
import Header from '../../components/header/Header'
import CartItem from './carrinhoFunções/cartItems'
import Observacoes from '../../components/observacoes'
import { CarrinhoBarPagamento } from './carrinhoFunções/carrinhoBarPagamento'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CompraProvider } from './carrinhoFunções/compracontext'

export default function CarrinhoMain(){
  const [observacoesCart, setObservacao] = useState('');
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart.cart);
  
return(
  <>
      <div className='pagina pb-[65px]'>
        <div className='Main'>  
          <div>
            <Header/>
          </div>
          <CompraProvider>
              <CartItem/>
              {
                cart.length > 0 && (
                  <>
                    <div style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu')}}> 
                      <div className='p-4 pl-7 '> {t('observacao').toUpperCase()} </div>
                    </div>
                    <div className='h-[150px]'>
                      <Observacoes
                        setObservacao={setObservacao}
                      />
                    </div>
                    <div>
                      <CarrinhoBarPagamento
                        observacoesCart={observacoesCart}
                      />
                    </div>
                  </>
              )}
          </CompraProvider>          
        </div>
      </div>
  </>
  )
}