import React, { useEffect, useState } from "react";
import { getDescricaoProduto, getlistaSaboresPizza } from "../../api/chamados/get/produtos";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatarValor, JanelaMensagem } from "../../components/funcoes/funcoes";
import Decimal from "decimal.js";
import { FaWindowClose } from "react-icons/fa";
import { removeItem, updateCartItem } from '../../redux/cartSlice'
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { AnimatePresence, motion } from 'framer-motion';

export default function ModalPizza({ modalPizza, setModalPizza, setOpcionaispizza }){
    const cart = useSelector((state) => state.cart.cart);
    const item = cart?.[cart.length - 1];
    const { t } = useTranslation();
    const [isClosing, setIsClosing] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        // Desabilita o scroll quando o modal está aberto
        document.body.style.overflow = modalPizza ? 'hidden' : 'auto';
    
        // Limpeza do efeito: reabilita o scroll ao fechar o modal
        return () => {
          document.body.style.overflow = 'auto';
        };
    }, [modalPizza]);
    
    if (!modalPizza) return null;

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    if (!modalPizza && !isClosing) return null;

    const cancelar = ()=>{
        setIsClosing(true); // Ativa o estado de fechamento
        setTimeout(() => {
            setModalPizza(false)// Fecha o modal após a animação de saída
            dispatch(removeItem(item.id));
        }, 600); 
    }

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
        <AnimatePresence>
            {!isClosing && modalPizza && (
                <>
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={overlayVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    />
                    <motion.div
                        className="modal-container"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    >
                        <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black h-[90vh]`} style={{ width: '97%' }}>
                            <div className="grid place-items-center p-1 font-medium text-lg" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }} > {t('pizza').toUpperCase()} </div>
                            <SaboresPizza item={item} tamanhoPizza={item.tamanhoPizza} setModalPizza={setModalPizza} setOpcionaispizza={setOpcionaispizza} setIsClosing={setIsClosing} cancelar={cancelar} />
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}

function SaboresPizza({ item, tamanhoPizza, setModalPizza, setOpcionaispizza, setIsClosing, cancelar }){
    const [sabores, setSabores] = useState([]);
    const [totalQuantidade, setTotalQuantidade] = useState(0);
    const [totalpizza, setTotalPizza] = useState(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchpizza, setSearchpizza] = useState('');
    
    useEffect(()=>{
        handleSabores()
    }, [])
    useEffect(()=>{
        const totalQuantity = sabores?.reduce((accumulator, item) => accumulator + item.quantidade, 0);
            setTotalQuantidade(totalQuantity)
        handletotalpizza()
    }, [sabores])

    const handletotalpizza = () => {
        const totalQuantity = sabores?.reduce((accumulator, item) => accumulator + item.quantidade, 0);
        if (tamanhoPizza.VALOR_MAIOR_MISTA === "NAO") {
            const totalItem = sabores.reduce((acc, item) => {
                const valorVenda = new Decimal(item.VALOR_VENDA || 0);
                const quantidade = new Decimal(item.quantidade || 0);
                const multipliedValue = valorVenda.times(quantidade);                
                if (totalQuantity > 0) {
                    const dividedValue = multipliedValue.dividedBy(totalQuantity);
                    return acc.plus(dividedValue);
                } else {
                    return acc;
                }
            }, new Decimal(0));
    
            setTotalPizza(totalItem.toFixed(2));
        } else if (tamanhoPizza.VALOR_MAIOR_MISTA === "SIM") {
            const totalItem = sabores.reduce((acc, item) => {
                const valorVenda = new Decimal(item.VALOR_VENDA || 0);
                const quantidade = new Decimal(item.quantidade || 0);
                const multipliedValue = valorVenda.times(quantidade);
                return acc.plus(multipliedValue);
            }, new Decimal(0));
    
            setTotalPizza(totalItem.toFixed(2));
        }
    };
    
    const handleSabores= async ()=>{
        const response = await getlistaSaboresPizza(tamanhoPizza.ID);
        const updatedData = response?.map((item) => ({
            ...item,
            quantidade: 0, 
        }));
        setSabores(updatedData)
    }

    const aumentarQuantidade = (id) => {
        const updatedListaOpcionais = sabores.map((sabor) => {
            if (sabor.ID === id) {
                const quantidade = new Decimal(sabor.quantidade);
                return {
                    ...sabor,
                    quantidade: quantidade.plus(1).toNumber()
                };
            }
            return sabor;
        });
        setSabores(updatedListaOpcionais);
    };

    const diminuirQuantidade = (id) => {
        const updatedListaOpcionais = sabores.map((sabor) => {
            if (sabor.ID === id && new Decimal(sabor.quantidade).gt(0)) {
                const quantidade = new Decimal(sabor.quantidade);
                return {
                    ...sabor,
                    quantidade: quantidade.minus(1).toNumber()
                };
            }
            return sabor;
        });
        setSabores(updatedListaOpcionais);
    };

    const removerSaborPizza = (itemClicado) => {
        const updatedListaOpcionais = [...sabores];
        const removedSabor = itemClicado.PRODUTO.toLowerCase();
        const removedID_GRADE = itemClicado.ID_GRADE;
        const index = updatedListaOpcionais.findIndex(
          item =>
            item.ID_GRADE === removedID_GRADE && item?.PRODUTO?.toLowerCase() === removedSabor
        );
        if (index !== -1) {
          updatedListaOpcionais[index].quantidade = 0;
          setSabores(updatedListaOpcionais);
        }
    };

    const addtocart=()=>{
        if(totalQuantidade <= 0){
            JanelaMensagem(t('adicionarsabor'), 'amarelo')
        } else {
            const updatedItem = {
                sabores: sabores.filter(item => item.quantidade > 0),
                total: totalpizza
            }
            dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));
            setIsClosing(true)
            setTimeout(() => {
                setModalPizza(false)
                setOpcionaispizza(true)                
            }, 600);
        }
    }

    const filterpizza = sabores?.filter((item) =>
        item.PRODUTO && item.PRODUTO.toLowerCase().includes(searchpizza.toLowerCase())
    );

    return(
        <div>
            <div className="w-full grid place-items-center">
                <input className="border w-full rounded p-2 pl-10 shadow w-[96%] mt-2" placeholder={t('searchpizza')} value={searchpizza} onChange={(e)=> setSearchpizza(e.target.value)}/>
            </div>
            <div className="flex overflow-x-auto whitespace-nowrap shadow hide-scrollbar h-[50px]">
                { 
                    sabores?.filter(item => item.quantidade > 0).map((item, index) => 
                        <div key={index} className="p-1 flex place-items-center boxshadowsubgrupo m-2" onClick={() => removerSaborPizza(item)}>
                            <div className="w-full"> {item.PRODUTO} </div>
                            <div className="ml-3">
                                <FaWindowClose size={25}/>
                            </div>
                        </div>
                    ) 
                }
            </div>
            <div style={{ height: '65vh', overflow: 'auto' }}>
                {
                    filterpizza.length > 0 ? (
                        Object.entries(filterpizza.reduce((acc, item) => {
                            if (!acc[item.SUBGRUPOS]) {
                                acc[item.SUBGRUPOS] = [];
                            }
                            acc[item.SUBGRUPOS].push(item);
                            return acc;
                        }, {})).map(([subgrupo, items]) => (
                            <div key={subgrupo}>
                                <div className="m-1 border-b-2">
                                    {`${subgrupo}`.toUpperCase()}
                                </div>
                                {items.map((item, index) => (
                                    <div key={index} className="flex justify-between p-1 mt-2 mb-2 boxshadowproduto" style={{ backgroundColor: item.quantidade > 0 ? 'rgb(187 247 208)' : '' }}>
                                        <div>
                                            <div>{item.PRODUTO}</div>
                                            <div>
                                                <Descricao itemID={item.ID} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="grid place-items-center" style={{ color: localStorage.getItem('corvalor') }}> {formatarValor(item.VALOR_VENDA)} </div>
                                            <div className='flex'>
                                                <div className='grid place-items-center'>
                                                    <button className='' onClick={() => diminuirQuantidade(item.ID)}>
                                                        <CiSquareMinus size={50} />
                                                    </button>
                                                </div>
                                                <div className='grid place-items-center m-1 font-medium text-lg'> {item.quantidade} </div>
                                                <div className='grid place-items-center'>
                                                    <button className='' onClick={() => aumentarQuantidade(item.ID)} disabled={totalQuantidade === tamanhoPizza.QTD_MAXIMO}>
                                                        <CiSquarePlus size={50} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : null
                }
            </div>
            <div className="flex justify-between">
                <button className="p-3 m-2 bg-red-500 text-white rounded" onClick={cancelar}> {t('cancelar').toUpperCase()} </button>
                <button className="p-3 m-2 bg-black text-white rounded dark:bg-white dark:text-black" onClick={addtocart}> {t('inserir').toUpperCase()} </button>
            </div>
        </div>
    )
}

function Descricao({ itemID }){
    const [descricao, setDescricao] = useState('');

    useEffect(()=>{
        handleDescricaoProduto()
    }, [itemID])

    const handleDescricaoProduto= async ()=>{
        const res = await getDescricaoProduto(itemID)
            setDescricao(res)
    }
    return(
        <>
            { descricao? (
                <div className="h-12 overflow-auto">
                    <div className="ml-2 text-sm"> {descricao?.toLowerCase()} </div>
                </div>
            ) : null}
        </>
        
    )
}
