import React, { useEffect, useState } from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './style.css'
import BtnsFlutuantes from './components/navBar/btnsFlutuantes';
import { moedaAtiva, parametrosCardapio } from './api/chamados/get/empresa';
import { detectarMobile } from './components/funcoes/funcoes';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { CartProvider } from './components/cartContext';

const queryClient = new QueryClient();

const RootComponent = () => {
  const [mobile, setMobile] = useState(false);
  const darkModePreference = localStorage.getItem('darkmode');
  const [pagina, setPagina] = useState('');
    
  useEffect(()=>{
    const path = location.pathname;
    const nome = path.substring(path.lastIndexOf('/') + 1);
      setPagina(nome);
  }, [location.pathname])
  
  useEffect(()=>{
    parametrosCardapio()
  }, [])

  useEffect(()=>{
    if (darkModePreference === 'dark') {
      document.documentElement.classList.add('dark');
    } else if (darkModePreference === 'light') {
      document.documentElement.classList.remove('dark');
    }
  }, [darkModePreference])

  useEffect(() => {
    handleMoeda();
    setMobile(detectarMobile())
  }, []);

  const handleMoeda = async () => {
    const moeda = await moedaAtiva();
    localStorage.setItem('moeda', moeda);
  };
  
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <Provider store={store}>
            <div className="app dark:bg-[rgb(0,0,0,0.95)]" style={{ color: localStorage.getItem('corfonte'), fontFamily: localStorage.getItem('fontecardapio') }}>
              <div style={{ paddingBottom: pagina === '' ? 0 : (mobile ? 90 : 50) }}>
                <App/>
              </div>
              <BtnsFlutuantes/>
            </div>        
          </Provider>
        </CartProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
