import axios from 'axios';

const hostname = window.location.hostname;
const port = window.location.port;
//const rotaLocal = 'http://192.168.0.93:9864/api'
const rotaLocal = `https://cardapio.garline.com.br/proxy/api`;
//const rotaLocal = `http://${hostname}:${port}/api`;

export const conexao = axios.create({
    baseURL: rotaLocal
});