import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getPromocoes, imagemProduto } from "../../api/chamados/get/produtos";
import { formatarValor } from "../../components/funcoes/funcoes";
import { t } from 'i18next'
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import './style.css'

export default function Promocoes({ modalOpcionais, setModalOpcionais, setModalPizza  }) {
    const [listaPromocoes, setListaPromocoes] = useState([]);
    const [isSelectingProduct, setIsSelectingProduct] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        handlePromocoes();
    }, []);

    useEffect(()=>{
        if(modalOpcionais === false){
            setIsSelectingProduct(false)
        }
    }, [modalOpcionais])
        
    const handlePromocoes = async () => {
        const lista = await getPromocoes();
        if (lista) {
            setListaPromocoes(lista);
        }
    };
    const handleProdutoPromocao= async (item)=>{
        if(item.PIZZA_MISTA === 'SIM'){
            return      
        } else {    
            handleSelectProduto(item)
        }        
    }
    const handleSelectProduto = async (value) => {
        if (isSelectingProduct) return; // Se já está selecionando, não faz nada

        setIsSelectingProduct(true); // Marca que a seleção está em andamento

        const item = {
          produto: value,
          total: value.VALOR_VENDA.toFixed(2),
          totalOP: 0,
          opcionais: [],
          tamanhoProduto: '',
          observacoes: '',
          mista: 'NAO'
        };

        await dispatch(addToCart(item));
        setModalOpcionais(true);
    };

    return (
        <>
            {listaPromocoes?.length > 0 ? (
                <div className="flex overflow-x-auto">
                    {listaPromocoes.map((item, index) => (
                        <div key={index} className="relative m-2 grid place-items-center">
                            <button onClick={() => handleProdutoPromocao(item)} className="rounded shadow relative w-full h-full boxcardpromo flex flex-col">
                                <span></span>
                                <Imgproduto itemID={item.ID_PRODUTO} />
                                <div className="grid place-items-center w-full mt-1">
                                    <div className="rounded w-[150px] bg-[rgba(0,0,0,0.1)] p-1 text-xs h-10 grid place-items-center">
                                        {item.PRODUTO}
                                    </div>
                                </div>
                                <div className="grid place-items-center w-full mt-2">
                                    <div className="bg-[rgba(0,0,0,0.1)] rounded p-2 relative w-[95%]">
                                        <del className="absolute top-0 left-2 text-sm">{formatarValor(item.VALOR_ANTIGO)}</del>
                                        <div className="grid items-end mt-2 text-xl" style={{ color: localStorage.getItem('corvalor') }}>
                                            {formatarValor(item.VALOR_VENDA)}
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            ) : null}
    </>
    );
}

function Imgproduto({ itemID }){
    const [img, setImg] = useState('');

    useEffect(()=>{
        handleGetImg()
    }, [itemID])

    const handleGetImg = async (id) => {
        try {
            const res = await imagemProduto(itemID);
                setImg(res)
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    return(
        <>
            {img ? 
                <div className="w-full grid place-items-center mt-1">
                    <img src={img} alt="Produto" className='rounded h-[120px] w-[120px]'/>
                </div>
            :   <div className="w-full grid place-items-center mt-1">
                    <div className='rounded w-[117px] h-[117px] bg-[rgba(255,255,255,0.1)]'/>
                </div>
            }
        </>
    )
}

function ScrollableText({ item }) {
    const textRef = useRef(null);
    const containerRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);
  
    useLayoutEffect(() => {
      const textWidth = textRef.current?.scrollWidth || 0;
      const containerWidth = containerRef.current?.offsetWidth || 0;
      setIsScrollable(textWidth > containerWidth);
    }, [item.PRODUTO]);
  
    return (
      <div className="whitespace-nowrap overflow-hidden text-ellipsis" ref={containerRef}>
        <div ref={textRef} style={{ display: 'inline-block', animation: isScrollable ? `scroll ${Math.min(10, (textRef.current?.scrollWidth / 100) * 5)}s linear infinite` : 'none', }}> {item.PRODUTO} </div>
      </div>
    );
}  
