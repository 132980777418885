import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { AiOutlineOrderedList, AiFillHome } from "react-icons/ai";
import { FaOpencart } from "react-icons/fa";
import { detectarMobile } from "../funcoes/funcoes";
import BarraCarrinhoAtalho from "./barraCarrinhoAtalho";
import { useSelector } from "react-redux";
import { useCart } from "../cartContext";

export default function BtnsFlutuantes(){
  const location = useLocation();
  const [aparelho, setAparelho] = useState('');
  const [pagina, setPagina] = useState('');
  const navigate = useNavigate('');
  const cart = useSelector((state) => state.cart.cart);
  const { setRef } = useCart(); // Pegue o setRef do contexto do carrinho
  const shoppingCartRef = useRef(null); // Crie uma ref local para o carrinho
  const [ativo, setAtivo] = useState(false);

  useEffect(()=>{
    const res = localStorage.getItem('cardapiosimples') === "SIM" ? false : true
      setAtivo(res)
    const path = location.pathname;
    const nome = path.substring(path.lastIndexOf('/') + 1);
      setPagina(nome);
  }, [location.pathname])

  useEffect(() => {
    setRef(shoppingCartRef.current); // Passa a referência do carrinho ao contexto
  }, []);

  useEffect(()=>{
    handleDetectarMobile();    
  }, []);

  const handleDetectarMobile = () => { 
    const dados = detectarMobile();
    setAparelho(dados);
  };

  const handleCart = () => {
    navigate('/Carrinho');
  };

  const handleMenu = () => {
    navigate('/Main');
  };

  const handlePedidos = () => {
    navigate('/Pedidos');
  };

  return (
    <div>
      {pagina === '' ? null : (
          aparelho === false ? (
            <div className="flex fixed bottom-2 left-2">
              <div className="rounded cursor-pointer p-1.5 ml-2" style={{ bottom: 130, background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }} onClick={handlePedidos}>
                <AiOutlineOrderedList size={30} color={pagina === 'Pedidos' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')} />  
              </div> 
              <div className="rounded cursor-pointer p-1.5 ml-2.5" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }} onClick={handleMenu}>
                <AiFillHome size={30} color={pagina === 'Main' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')}/>
              </div>               
              <div className="rounded cursor-pointer p-2.5 ml-3 shopping-cart" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }} onClick={handleCart} ref={shoppingCartRef}>
                <FaOpencart size={30} color={pagina === 'Carrinho' ? localStorage.getItem('coriconemenu') : localStorage.getItem('coriconemenuapagado')} />
                {cart.length > 0 ? (
                  <div className="h-7 w-7 grid place-items-center absolute top-[-10px] right-[-10px]" style={{ borderRadius: '50%', background: localStorage.getItem('corvalor') }}>
                    <div className='text-white'>{cart.length}</div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : aparelho === true ? (
            <BarraCarrinhoAtalho ativo={ativo} />
          ) : null
      )}
    </div>
  );
}
