import React, { useEffect, useState } from "react";
import { getbairrosdelivery, getcidadesdelivery, getdadosempresa } from "../../../api/chamados/get/empresa";
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import { formataNumerico, formatarValor, JanelaMensagem } from "../../../components/funcoes/funcoes";
import { CiCircleCheck } from "react-icons/ci";
import Decimal from "decimal.js";
import { useDispatch } from "react-redux";
import { salvarPedido } from "../../../redux/pedidosSlice";
import { clearCart } from '../../../redux/cartSlice'

export default function ModalPedirDelivery({ modalpedirdelivery, setModalpedirdelivery, compra, observacoesCart }) {
    const [isClosing, setIsClosing] = useState(false);
    const [index, setIndex] = useState(0);
    const [endereco, setEndereco] = useState({});
     
    const handleCancelar = () => {
        setIsClosing(true); // Ativa o estado de fechamento
        setTimeout(() => {
            setModalpedirdelivery(false); // Fecha o modal após a animação de saída            
        }, 600); // Duração igual ao tempo da animação de saída
    };

    React.useEffect(() => {
        document.body.style.overflow = modalpedirdelivery ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalpedirdelivery]);
    
    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    if (!modalpedirdelivery && !isClosing) return null;

    return (
        <AnimatePresence>
            {!isClosing && modalpedirdelivery && (
                <>
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={overlayVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    />
                    <motion.div
                        className="modal-container"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    >
                        <div className="modal bg-stone-100 rounded dark:bg-black" style={{ width: '97%' }}>
                            {
                                index === 0 &&
                                <Enderecoentrega setIndex={setIndex} setEndereco={setEndereco} oncancel={handleCancelar}/>
                            }   
                            {
                                index === 1 &&
                                <Pagamentodelivery observacoesCart={observacoesCart} endereco={endereco} setIsClosing={setIsClosing} setModalpedirdelivery={setModalpedirdelivery} compra={compra} oncancel={handleCancelar} />
                            }                                
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
}

function Enderecoentrega({ setIndex, oncancel, setEndereco }){
    const enderecosalvo = JSON.parse(localStorage.getItem('endereco'))
    const [listaCidade, setListaCidades] = useState([]);
    const [listaBairros, setListaBairros] = useState([]);
    const [nome, setNome] = useState(enderecosalvo?.nome || '');
    const [rua, setRua] = useState(enderecosalvo?.rua || '');
    const [numero, setNumero] = useState(enderecosalvo?.numero || '');
    const [bairro, setBairro] = useState(enderecosalvo?.bairro || '');
    const [complemento, setcomplemento] = useState(enderecosalvo?.complemento || '');
    const [cidade, setCidade] = useState(enderecosalvo?.cidade || '');
    const { t } = useTranslation();
    console.log(enderecosalvo)

    useEffect(()=>{
        handlecidades()
    }, [])

    const handlecidades = async ()=>{
        const response = await getcidadesdelivery()
            setListaCidades(response)
    }

    const selectcidade=(e)=>{
        setCidade(e)
        setBairro(0)
    }

    useEffect(()=>{
        if(cidade > 0){
            handlebairros()
        }
    }, [cidade])

    const handlebairros = async ()=>{
        const result = await getbairrosdelivery(cidade)
            setListaBairros(result)
    }

    const handleNumero=(number)=>{
        const result = formataNumerico(number)
            setNumero(result)
    }   

    const fazerpedido = ()=>{
        if(nome, rua, numero, bairro, cidade){
            setEndereco({nome, rua, numero, bairro, complemento, cidade})
            setIndex(1)
        } else {
            JanelaMensagem(t('preenchaendereco'), 'amarelo')
        }
    }   

    return(
        <>
            <div className="p-3 grid place-items-center rounded-t" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> {t('enderecoentrega').toUpperCase()} </div>
            <div className="relative m-2 mt-5"> 
                <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('nome')} </label>
                <input type="text" value={nome} onChange={(e)=> setNome(e.target.value)} className="pt-5 p-2 pl-5 shadow rounded w-full outline-none"/>
            </div>
            <div className="m-2 mt-5">
                <div className="flex flex-wrap md:flex-nowrap justify-between mt-5">
                    <div className="relative w-full md-w-3/4 md:mr-4">
                        <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('rua')} </label>
                        <input value={rua} onChange={(e)=> setRua(e.target.value)} className="pt-5 p-2 pl-5 shadow rounded w-full outline-none"/>
                    </div>
                    <div className="relative w-full md:w-1/4 mt-5 md:mt-0">
                        <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('numero')} </label>
                        <input type="tel" value={numero} maxLength={8} onChange={(e)=> handleNumero(e.target.value)} className="pt-5 p-2 pl-5 shadow rounded w-full outline-none"/>
                    </div>
                </div>
                <div className="relative mt-5">
                    <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('referencia')} </label>
                    <input value={complemento} onChange={(e)=> setcomplemento(e.target.value)} className="pt-5 p-2 pl-5 shadow rounded w-full outline-none"/>
                </div>
                <div className="flex flex-wrap md:flex-nowrap justify-between mt-5">
                    <div className="w-full relative">
                        <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('cidade')} </label>
                        <select className="p-4 w-full rounded outline-none" value={cidade} onChange={(e)=> selectcidade(e.target.value)}>
                            <option value="0"> {t('selecionecidade')} </option>
                            {
                                listaCidade?.map((item, index) =>
                                    <option key={index} value={item.ID}> {item.NOME} </option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap justify-between mt-5">
                    <div className="w-full relative">
                        <label className="absolute top-[-10px] left-2 bg-white pl-2 pr-2 rounded-t"> {t('bairro')} </label>
                        <select className="p-4 w-full rounded outline-none" value={bairro} onChange={(e)=> setBairro(e.target.value)}>
                            <option value="0"> {t('selecionebairro')} </option>
                            {
                                listaBairros?.map((item, index) =>
                                    <option key={index} value={item.ID}> {item.DESCRICAO} </option>
                                )
                            }
                        </select>
                    </div>
                </div>
            </div> 
            <div className="flex justify-between m-2 mt-8">
                <button className="p-3 bg-red-500 text-white rounded" onClick={oncancel}> {t('cancelar').toUpperCase()} </button>
                <button className="p-3 bg-black text-white rounded" onClick={fazerpedido}> {t('pagamento').toUpperCase()} </button>    
            </div>
        </>
    )
}

function Pagamentodelivery({ observacoesCart, endereco, setIsClosing, setModalpedirdelivery, compra, oncancel }){
    const [listaCidade, setListaCidades] = useState([]);
    const [listaBairros, setListaBairros] = useState([]);
    const [dadosempresa, setDadosEmpresa] = useState('');
    const cidade = listaCidade.find(item => item.ID = endereco.bairro)
    const bairro = listaBairros.find(item => item.ID = endereco.bairro)
    const [selectedPayment, setSelectedPayment] = useState('');
    const [troco, setTroco] = useState(0);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(()=>{
        handledadosempresa()
        handlecidades()
        handlebairros()
    }, [])

    const handledadosempresa = async ()=>{
        const result = await getdadosempresa()
            setDadosEmpresa(result)
    }

    const handlecidades = async ()=>{
        const response = await getcidadesdelivery()
            setListaCidades(response)
    }

    const handlebairros = async ()=>{
        const result = await getbairrosdelivery(endereco.cidade)
            setListaBairros(result)
    }

    useEffect(() => {
        handlempresa()
    }, []);

    const handlempresa= async ()=>{
        handletotal()
    }

    const handletotal = async () => {
        let total = new Decimal(0);    
        compra.forEach(item => {
            total = total.plus(new Decimal(item.totalitempedido || 0).times(item.quantidade || 1));
        });
        setTotal(total.toFixed(2));
    };

    const handlePaymentChange = (e) => {
        setSelectedPayment(e.target.value);
    };

    const handleTroco = (e) => {
        let valor = e.target.value.replace(/\D/g, '');
        let valorFormatado = (valor / 100).toFixed(2); 
        valorFormatado = valorFormatado
            .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona pontos nos milhares
        setTroco(valorFormatado);
    };

    const handlepedir= ()=>{
        if(selectedPayment){
            enviarpedidowpp()
        } else {
            JanelaMensagem(t('selecionarpagamento'), 'amarelo')
        }
    }

    const enviarpedidowpp= async ()=>{
        const mensagem = `${t('garlinedelivery').toUpperCase()}\n\n` +
        `${t('pedidodelivery').toUpperCase()}\n` +
        `\n *${t('pedido').toUpperCase()}*\n` +
        compra.map((item, index) => 
            ` *${item.quantidade}* - *${item.descricao}*` +
            (item.mista === 'SIM' ?  
            `\n ${t('sabores')}: ${item.sabores?.map((sabor) => `${sabor.quantidade} - ${sabor.sabor}`).join(', ')}` : '') +
            (item.opcionais.length > 0 ? 
            `\n ${t('opcionais')}:` +
            `${item.opcionais?.map((opcional) => 
                `\n${opcional.descricaogrupo}: ${opcional.quantidade} - ${opcional.descricao}`).join(', ')}` : '') +
            (item.observacao ? `\n${t('observacao')}: ${item.observacao}` : '') +
            (item?.retirados?.length > 0 ? 
                `\n ${t('removidos')}: ` + 
                item?.retirados?.map((retirado) => `${retirado?.DESCRICAO}`).join(', ') 
                : '' 
            ) +
            `\n                ${t('totalitem')}: *${formatarValor(item.totalitempedido * item.quantidade)}*` +      
            `\n ----------------------------------------------------` 
        ).join('\n\n') +
        (observacoesCart ? 
            `\n         ${t('obsentrega')}` +
            `\n ${observacoesCart}` 
            : '' ) +
        `\n\n    *${t('enderecoentrega').toUpperCase()}*\n` +
        `\n ${t('nome').toUpperCase()}:    *${endereco.nome.toUpperCase()}*` +
        `\n\n    ${t('rua')}: *${endereco.rua}*, n°: *${endereco.numero}*` +
        `\n    ${t('bairro')}: *${bairro.DESCRICAO}*` +
        `\n    ${t('cidade')}: *${cidade.NOME}*` +
        `\n    ${t('referencia')}: *${endereco.complemento || ''}*` +
        `\n ----------------------------------------------------` +
        `\n ${t('totalitems')}:                        *${formatarValor(total)}*` +
        `\n ${t('taxaEntrega')}:               *${formatarValor(bairro?.TAXA_ENTREGA)}*` +
        `\n ${t('total')}:                                   *${formatarValor(parseFloat(total) + parseFloat(bairro?.TAXA_ENTREGA))}*` +
        `\n ----------------------------------------------------` +
        `\n ${t('formapagamento').toUpperCase()}: *${selectedPayment.toUpperCase()}*` +
        (selectedPayment === 'pix' ? 
            `\n${t('beneficiario')}: *${dadosempresa.TITULAR}*\n${t('chavepix')}: *${dadosempresa.CHAVE_PIX}*\n`
            : ''
        ) +
        (troco ? 
            `\n ${t('troco')}: *${formatarValor(troco)}*` 
            : ''
        ) +
        `\n\n ${t('obg')} 😊`;
        const encodedMessage = encodeURIComponent(mensagem);  
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          window.location.href = `https://api.whatsapp.com/send?phone=${dadosempresa.WHATS}&text=${encodedMessage}`;
        } else {
          window.open(`https://wa.me/${dadosempresa.WHATS}?text=${encodedMessage}`, '_blank');
        } 
        handleSalvarPedido()
        handlesalvarendereco()
        fechar()
    } 
    
    const handleSalvarPedido = () => {
        const novoPedido = {
          id: Date.now(), 
          itens: compra,
        };
        dispatch(salvarPedido(novoPedido));   
        dispatch(clearCart())   
    };

    const handlesalvarendereco=()=>{
        localStorage.setItem('endereco', JSON.stringify(endereco))
    }

    const fechar=()=>{
        setIsClosing(true); // Ativa o estado de fechamento
        setTimeout(() => {
            setModalpedirdelivery(false); // Fecha o modal após a animação de saída
        }, 600);
    }
    
    return(
        <>
            <div className="p-3 grid place-items-center rounded-t" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> {('pagamento').toUpperCase()} </div>
            <div className="m-3 grid place-items-center text-xl text-black dark:text-white"> {t('pagamentoretirada')} </div>
            <div className="text-black p-3">
                <div className="flex justify-between full md:w-[300px]"> 
                    <div> {t('totalitems').toUpperCase()}: </div> 
                    <div> {formatarValor(total)} </div>
                </div> 
                <div className="flex justify-between full md:w-[300px]"> 
                    <div> {t('taxaEntrega').toUpperCase()}: </div> 
                    <div> {formatarValor(bairro?.TAXA_ENTREGA)} </div>
                </div>
                <div className="flex justify-between w-full md:w-[300px]"> 
                    <div> {t('total').toUpperCase()}: </div> 
                    <div> {formatarValor(parseFloat(total) + parseFloat(bairro?.TAXA_ENTREGA))} </div> 
                </div>
                <div className="flex justify-between w-full md:w-[300px]">
                    <div> {t('tempomedioesperadelivery').toUpperCase()} </div>
                    <div> {dadosempresa.TEMPO_ESPERA}Min </div>
                </div>
            </div>
            <div className="grid place-items-center m-2">
                <div className="w-full relative">
                    <label className="absolute top-2 left-5 text-xl text-white"> {t('pix').toUpperCase()} </label>
                    <input type="radio" name="pagamento" id="pix" value="pix" checked={selectedPayment === 'pix'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
                    {selectedPayment === 'pix' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
                </div>
                <div className="w-full relative">
                    <label className="absolute top-2 left-5 text-xl text-white"> {t('cartao').toUpperCase()} </label>
                    <input type="radio" name="pagamento" id="cartao" value="cartao" checked={selectedPayment === 'cartao'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
                    {selectedPayment === 'cartao' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
                </div>
                <div className="w-full relative">
                    <label className="absolute top-2 left-5 text-xl text-white"> {t('dinheiro').toUpperCase()} </label>
                    <input type="radio" name="pagamento" id="dinheiro" value="dinheiro" checked={selectedPayment === 'dinheiro'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
                    {selectedPayment === 'dinheiro' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
                </div>
                {
                    selectedPayment === 'dinheiro' && 
                    <motion.div
                        className="p-4 bg-gray-100 rounded shadow w-full"
                        initial={{ x: "100%" }}
                        animate={{ x: 0 }}
                        exit={{ x: "100%" }}
                        transition={{ type: "spring", stiffness: 100 }}
                    >
                        <div className="relative mb-5">
                            <div className="text-black"> {t('troco').toUpperCase()} </div>
                            <label className="absolute top-9 left-2" style={{ color: localStorage.getItem('corvalor') }}> {localStorage.getItem('moeda') === 'BRL' ? 'R$' : '$'} </label>
                            <input type="tel" className="w-full p-3 pl-9 rounded shadow outline-none text-black" value={troco} onChange={handleTroco}/>
                        </div>
                    </motion.div>
                }
                <div className="flex justify-between m-2 mt-3 w-full">
                    <button className="p-3 bg-red-500 text-white rounded" onClick={oncancel}> {t('cancelar').toUpperCase()} </button>
                    <button className="p-3 bg-black text-white rounded" onClick={handlepedir}> {t('fazerpedido').toUpperCase()} </button>    
                </div>
            </div>
        </>
    )
}