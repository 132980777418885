import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearCart, removeItem, incrementQuantity, decrementQuantity} from '../../../redux/cartSlice';
import Decimal from 'decimal.js';
import ModalInfoCart from './modalInfoCart'
import './style.css'
import { LiaOpencart } from "react-icons/lia";
import { formatarValor } from '../../../components/funcoes/funcoes'
import { t } from 'i18next'
import { TbTrashXFilled } from "react-icons/tb";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { BsCartX } from "react-icons/bs";

export default function CartItem() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const [totalCart, setTotalCart] = useState('');
  const [modalInfoCart, setmodal] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState('');

  useEffect(() => {
    if (cart && Array.isArray(cart)) {
      let total = new Decimal(0) || 0;
      cart.forEach(item => {
        total = total.plus(new Decimal(item.quantity || 0).times(item.total || 0));
      });
      setTotalCart(total.toFixed(2));
    }
  }, [cart]);

  const handleAbrirmodal=(item)=>{
    setItemSelecionado(item)
    setmodal(true)
  }

  const boxShadowClass2 = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

  return (
    <div className='MainCartList'>
      <div className='CartList'>
        <div className='cartTitulo' style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> 
          <div className='ml-5'>
            <LiaOpencart size={40} />
          </div>
          <div className='carrinhoName'> {t('carrinho').toUpperCase()} </div>
        </div>
        <div className='cartListItems'>
          {
            cart.length > 0 ? (
              cart?.map((item, index)=>
              <div className={`${boxShadowClass2} cardCart mt-2 mb-2 pt-3 pb-3 pr-5`} key={index}>
                <div className='cartBoxIconeLixeira'>
                  <button onClick={()=> dispatch(removeItem(item.id))}>
                    <TbTrashXFilled size={30} />
                  </button>
                </div>
                <div className='w-full' onClick={()=> handleAbrirmodal(item)}>
                  <div className='boxDescricaoNome'> {item.mista === "SIM" ? (<div> {item?.tamanhoPizza?.TAMANHO} </div>) : (<div> {item?.produto?.PRODUTO} </div>) } </div>
                  <div className='grid place-items-start'>
                    <div className='text-red-500 border-2 border-red-500 text-lg pl-2 pr-2 rounded-lg'> 
                      {formatarValor((item.total*item.quantity).toFixed(2))} 
                    </div>
                  </div>
                </div>
                <div className='w-1/4 flex'>
                  <div className='cartbtnquantia'>
                    {
                      item.quantity > 1 ? (
                        <button className='p-1' onClick={() => dispatch(decrementQuantity(item.id))}>
                          <CiSquareMinus size={50} />
                        </button>) : null
                    }
                  </div>
                    <div className='grid place-items-center w-5'>{item.quantity}</div>
                  <div className='cartbtnquantia'>
                    <button className='p-1' onClick={() => dispatch(incrementQuantity(item.id))}>
                      <CiSquarePlus size={50} />
                    </button>
                  </div>
                </div>
              </div>
              )
            ) : (
              <div className='grid place-items-center p-2 h-[50vh]'> 
                <div>
                  <div className='grid place-items-center'> <BsCartX size={80} color='grey'/>  </div>
                  <div className='mt-5 text-stone-500'> {t('carrinhovazio').toUpperCase()} </div> 
                </div>
              </div>
            )
          }
        </div> 
        {
          cart.length > 0 ?(
            <div className='barraTotalCartList' style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu')}}>
              <div className='flex justify-between place-items-center'  onClick={()=> dispatch(clearCart())}>
                <button className='ml-5'>
                  <TbTrashXFilled size={30} />
                </button>
                <div className='ml-5'> {t('limpar').toUpperCase()} </div>
              </div>
              <div className='totalCartList'>
                <div> {t('total').toUpperCase()} {formatarValor(totalCart)}
              </div>
              </div>
            </div>
          ) : null
        }
      </div>
      {modalInfoCart && <ModalInfoCart item={itemSelecionado} modalInfoCart={modalInfoCart} setmodal={setmodal} />}
    </div>
  )
}

