import { createSlice } from '@reduxjs/toolkit';

const pedidosSlice = createSlice({
  name: 'pedidos',
  initialState: {
    pedidos: [],
  },
  reducers: {
    salvarPedido: (state, action) => {
      state.pedidos.push(action.payload);
    },
    removerPedido: (state, action) => {
      state.pedidos = state.pedidos.filter(pedido => pedido.id !== action.payload);
    },
    carregarPedidos: (state, action) => {
      state.pedidos = action.payload;
    },
  },
});

export const { salvarPedido, removerPedido, carregarPedidos } = pedidosSlice.actions;
export default pedidosSlice.reducer;
