import { useEffect, useState, useRef } from 'react';
import ModalOpcionais from '../opcionais/modalOpcionais';
import { IoSearchSharp } from "react-icons/io5";
import { getlistaTamanhosPizza, getSubgrupos } from '../../api/chamados/get/produtos';
import ProdutoList from './listaProdutos';
import { useTranslation } from 'react-i18next';
import { addToCart } from '../../redux/cartSlice';
import ModalPizza from './modalPizza'
import { useDispatch } from 'react-redux';
import ModalOpcionaisPizza from '../opcionais/modalOpcionaisPizza';
import IconsSubGrupos from '../../components/iconsSubGrupos';
import Promocoes from '../promocoes/promocoes'

export default function ProdutosPorGrupo() {
    const [busca, setBusca] = useState('');
    const [modalOpcionais, setModalOpcionais] = useState(false);
    const [modalPizza, setModalPizza] = useState(false);
    const [opcionaispizza, setOpcionaispizza] = useState(false);
      
    return (
        <>
            <div className='relative'>
                <Busca setBusca={setBusca} />
                <Promocoes modalOpcionais={modalOpcionais} setModalOpcionais={setModalOpcionais} setModalPizza={setModalPizza} />
                <SubGrupos busca={busca} modalOpcionais={modalOpcionais} setModalOpcionais={setModalOpcionais} setModalPizza={setModalPizza} />
            </div>
            {modalOpcionais && <ModalOpcionais modalOpcionais={modalOpcionais} setModalOpcionais={setModalOpcionais} cartmodal={false} />}
            {modalPizza && <ModalPizza modalPizza={modalPizza} setModalPizza={setModalPizza} setOpcionaispizza={setOpcionaispizza} />}
            {opcionaispizza && <ModalOpcionaisPizza opcionaispizza={opcionaispizza} setOpcionaispizza={setOpcionaispizza} />}
        </>
    );
}

function Busca({ setBusca }) {
    const { t } = useTranslation();
    return (
        <div className='sticky top-[0] z-10  p-3 relative bg-white dark:bg-black'>
            <input className='border w-full rounded p-2 pl-10 shadow' placeholder={t('pesquise')} onChange={(e) => setBusca(e.target.value)} />
            <div className='absolute top-6 left-5'>
                <IoSearchSharp color='grey' size={20} />
            </div>
        </div>
    );
}



function SubGrupos({ busca, modalOpcionais, setModalOpcionais, setModalPizza }) {
    const [subgrupos, setSubgrupos] = useState([]);
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    const subgruposRefs = useRef({});

    const scrollToSubGrupo = (id) => {
        const ref = subgruposRefs.current[id];
        if (ref) {
            const offsetTop = 210; 
            const elementTop = ref.getBoundingClientRect().top + window.pageYOffset;    
            window.scrollTo({
                top: elementTop - offsetTop,
                behavior: 'smooth'
            });
        }
    };

    function checkScreenOrientation() {
        setIsLandscape(window.innerWidth > window.innerHeight);
    }

    useEffect(() => {
        // Adiciona o event listener para redimensionamento
        window.addEventListener('resize', checkScreenOrientation);

        // Remove o event listener quando o componente for desmontado
        return () => {
            window.removeEventListener('resize', checkScreenOrientation);
        };
    }, []);

    return (
        <div className={`${isLandscape ? 'flex': ''}`}>
            <div className={`sticky ${isLandscape ? '' : 'fixed top-14 left-0 right-0'}`}>
                <BarraSubgrupos 
                    isLandscape={isLandscape}
                    subgrupos={subgrupos} 
                    setSubgrupos={setSubgrupos} 
                    scrollToSubGrupo={scrollToSubGrupo} 
                />
            </div>
            <div className={`flex-grow`}>
                {subgrupos?.length > 0 ? (
                    subgrupos.map((item, index) => (
                        item.PIZZA_MISTA === 'SIM' ? (
                            <div key={index} ref={(el) => subgruposRefs.current[item.ID] = el}>
                                <Tamanhos setModalPizza={setModalPizza} />
                            </div>
                        ) : (
                            <div key={index} ref={(el) => subgruposRefs.current[item.ID] = el}>
                                <ProdutoList
                                    busca={busca}
                                    idsubgrupo={item.ID}
                                    descricao={item.DESCRICAO}
                                    modalOpcionais={modalOpcionais}
                                    setModalOpcionais={setModalOpcionais}
                                />
                            </div>
                        )
                    ))
                ) : null}
            </div>
        </div>
    );
}

function BarraSubgrupos({ isLandscape, subgrupos, setSubgrupos, scrollToSubGrupo }) {

    useEffect(() => {
        handleSubgrupos();
    }, []);

    const handleSubgrupos = async () => {
        const res = await getSubgrupos();
        setSubgrupos(res);
    };

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowsubgrupo'

    const barra = isLandscape
        ? 'absolute top-14 sticky left-0 h-[100vh] overflow-y-auto grid pb-28' 
        : '';

    return (
        <div className={`${barra} sticky z-9 bg-white overflow-x-auto flex place-items-center shadow p-2 dark:bg-black`}>
            {subgrupos?.length > 0 ? (
                subgrupos.map((item, index) => (
                    <button onClick={() => scrollToSubGrupo(item.ID)} key={index} className="inline-block flex-col place-items-center ml-2 mr-4 mt-3" style={{ minWidth: 125, maxWidth: 125 }}>
                        <div className='grid place-items-center'>
                            <div className={`${boxShadowClass} p-3 grid place-items-center`} style={{ borderRadius: '50%' }}>
                                <IconsSubGrupos iconeSubgrupo={parseInt(item.ID_ICONE)} />
                            </div>
                        </div>
                        <div className='break-words mt-2 text-xs font-bold h-8'>
                            {item.DESCRICAO}
                        </div>
                    </button>
                ))
            ) : null}
        </div>
    );
    
}

function Tamanhos({ setModalPizza }){
    const [tamanhosPizza, setTamanhosPizza] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(()=>{
        handleTamanhos()
    }, [])

    const handleTamanhos= async ()=>{
        const res = await getlistaTamanhosPizza()
        setTamanhosPizza(res)
    }
    
    const handlePizza=(pizza)=>{
        const item = {
            produto: '',
            total: 0,
            totalOP: 0,
            opcionais: [],
            tamanhoProduto: '',
            observacoes: '',
            tamanhoPizza: pizza,
            mista: 'SIM'
        };
        dispatch(addToCart(item));
        setModalPizza(true);
    }

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
        <div>
            {
                tamanhosPizza?.length > 0 ?(
                    <>
                        <div className='text-xl border-b-2 ml-2 mr-2 mt-2'>{t('pizza').toUpperCase()}</div>
                        { 
                            tamanhosPizza.map((item, index) =>
                                <div key={index} className={`${boxShadowClass} rounded m-2 mt-5 p-2 flex justify-between`} onClick={()=> handlePizza(item)}>
                                    <div className='grid'>
                                        <div className='font-bold'> {item.TAMANHO} </div>
                                        <div className='grid items-end'> {t('quantidademaxima')} {item.QTD_MINIMO} </div>
                                    </div>
                                    <div>
                                        <div className='imgpizza rounded'></div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                ) : null
            }
        </div>
    )
}