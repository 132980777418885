import { useState } from 'react';
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from "react-icons/ai";

export default function Header() {
    const [isFullscreen, setIsFullscreen] = useState('');

    const handleFullScreen=()=>{
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullscreen(true);
          } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setIsFullscreen(false);
          }
    }

    const posicaologo = localStorage.getItem('logopos')

    return (
        <div className='relative'>
            <div
                className={`bg-cover bg-center grid ${posicaologo === 'DIREITA' ? 'place-items-end' : posicaologo === 'CENTRO' ? 'place-items-center' : 'place-items-start'}`}
                style={{
                    background: localStorage.getItem('tipoheader') === 'SÓLIDO'
                    ? localStorage.getItem('corheader') 
                    : 'transparent',
                    backgroundImage: localStorage.getItem('tipoheader') === 'SÓLIDO'
                    ? 'none'
                    : `url(${localStorage.getItem('imgheader')})`, 
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                {localStorage.getItem('imglogo') ? (
                    <img src={localStorage.getItem('imglogo')} className='h-48 m-5 rounded' alt='Logo'/>
                ) : ( <div className='h-52'></div> ) }
            </div>
            <button className='absolute bottom-2 left-2' onClick={handleFullScreen}>
                {
                    isFullscreen ? <AiOutlineFullscreenExit color='grey' size={40}/> : <AiOutlineFullscreen color='grey' size={40}/>
                }
            </button>
        </div>
    );
}
