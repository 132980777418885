import './style.css'
import React from 'react';
import { PiArrowsOut, PiArrowsInLight } from "react-icons/pi";
import { parametrosCardapio } from '../../api/chamados/get/empresa';

export const detectarMobile=()=> { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       return(true)
    }
    else {
       return(false)
    }
}

export const formCurrencyBRL = new Intl.NumberFormat('pt-BR', {
   style: 'currency',
   currency: 'BRL',
   minimumFractionDigits: 2
})

export const formCurrencyArgentina = new Intl.NumberFormat('es-AR', {
   style: 'currency',
   currency: 'ARS',
   minimumFractionDigits: 2
});

export const formCurrencyUSD = new Intl.NumberFormat('en-US', {
   style: 'currency',
   currency: 'USD',
   minimumFractionDigits: 2
});

export const JanelaMensagem = (mensagem, cor) => {
   const notification = document.createElement('div');
   notification.className = `janela + ${cor}`;
   notification.textContent = mensagem;
   
   document.body.appendChild(notification);
   
   setTimeout(() => {
     notification.style.opacity = '1';
   }, 10); 
   
   setTimeout(() => {
     notification.style.opacity = '0';
     setTimeout(() => {
       document.body.removeChild(notification);
     }, 300); 
   }, 5000); 
};

export const formatarValor = (valor) => {
   const moeda = localStorage.getItem('moeda')
   if (moeda === 'BRL') {
      return formCurrencyBRL.format(valor);
   } else if (moeda === 'ARS') {
      return formCurrencyArgentina.format(valor);
   }
   return valor;
}; 

export default function BtnFullScreen() {
   const [isFullscreen, setIsFullscreen] = React.useState(false);
 
   const handleFullScreen = () => {
     if (!document.fullscreenElement) {
       document.documentElement.requestFullscreen();
       setIsFullscreen(true);
     } else if (document.exitFullscreen) {
       document.exitFullscreen();
       setIsFullscreen(false);
     }
   };
   
 
   return (
     <div style={{ position: 'absolute', top: 160, right: 10 }}>
       <button onClick={handleFullScreen}>
         {isFullscreen ? <PiArrowsInLight color='grey' size={30} /> : <PiArrowsOut color='grey' size={30} />}
       </button>
     </div>
   );
 }

export const verificatipocardapio = async (tipoComanda, numeroComanda)=>{
  const ativo = tipoComanda && numeroComanda ? true : false;
  const dados = await parametrosCardapio();
  const abreviado = dados?.MODO_PRODUTOS === '1 - EXIBIÇÃO' 
      ? 'SIM' 
      : (ativo ? 'NAO' : 'SIM');
      localStorage.setItem('cardapiosimples', abreviado);
}

export const normalizeCepNumber = (value) => {
  if (!value) return ''
  return value.replace(/\D/g, "")
  .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
  .replace(/(-\d{3})(\d+?)/, '$1')    
}

export const formataNumerico = (value) => {
  return value.replace(/\D/g, '');
};