import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AiFillPlusSquare } from "react-icons/ai";
import './style.css';
import React, { useState } from 'react';
import ModalOpcionais from '../../opcionais/modalOpcionais'
import ModalOpcionaisPizza from '../../opcionais/modalOpcionaisPizza'
import { AnimatePresence, motion } from 'framer-motion';

export default function ModalInfoCart({ item, modalInfoCart, setmodal }) {
    const [modalEditarOpcionais, setModalEditarOpcionais] = useState(false);
    const [opcionaispizza, setOpcionaispizza] = useState(false);
    const { t } = useTranslation();
    const dadosItem = useSelector((state) => state.cart.cart.find((data) => data.id === item.id));
    const [isClosing, setIsClosing] = useState(false);

    const handleAddOP=()=>{
        if(item.mista === 'SIM'){
            setOpcionaispizza(true)
        } else {
            setModalEditarOpcionais(true)
        } 
    }

    React.useEffect(() => {
        // Desabilita o scroll quando o modal está aberto
        document.body.style.overflow = modalInfoCart ? 'hidden' : 'auto';
    
        // Limpeza do efeito: reabilita o scroll ao fechar o modal
        return () => {
          document.body.style.overflow = 'auto';
        };
    }, [modalInfoCart]);
    
    if (!modalInfoCart) return null;

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    const handleFechar=()=>{
        setIsClosing(true)
        setTimeout(() => {
            setmodal(false); // Fecha o modal após a animação de saída
        }, 600); 
    }

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return (
        <>
            <AnimatePresence>
                {!isClosing && modalInfoCart && (
                    <>
                        <motion.div
                            className="overlay"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={overlayVariants}
                            transition={{ duration: 0.4, ease: 'easeOut' }}
                        />
                        <motion.div
                            className="modal-container"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={modalVariants}
                            transition={{ duration: 0.4, ease: 'easeOut' }}
                        >
                            <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black`} style={{ width: '97%' }}>
                                <div className='grid place-items-center text-2xl h-10' style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}>
                                    {dadosItem?.mista === "SIM" ? (
                                        <div>{dadosItem?.tamanhoPizza?.TAMANHO}</div>
                                    ) : (
                                        <div>{dadosItem?.produto?.PRODUTO}</div>
                                    )}
                                </div>
                                <div style={{ minHeight: 100 }}>
                                    {dadosItem?.mista === "SIM" && (
                                        <div className='saboresmodalcartinfo'>
                                            <div className='grid'>
                                                <div className='w-28 bg-[#003A18] text-white mt-2 p-2 rounded-r'> 
                                                    {t('sabores').toUpperCase()}
                                                </div>
                                            </div>
                                            <div>
                                                {dadosItem?.sabores?.map((sabor, index) => (
                                                    <div key={index} className='flex p-2 shadow ml-1 mr-1'>
                                                        <div className='w-14 grid place-items-center'>{'qtd: ' + sabor?.quantidade}</div>
                                                        <div className='w-full grid justify-end'>{sabor?.PRODUTO?.toLowerCase()}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {dadosItem?.opcionais?.length > 0 && (
                                        <div key={dadosItem?.opcionais?.ID}>
                                            <div className='grid'>
                                                <div className='w-28 bg-[#003A18] text-white mt-2 p-2 rounded-r'> {t('adicionais').toUpperCase()} </div>
                                            </div>                                            
                                            <div>
                                                {dadosItem?.opcionais?.map((opcional, index) => (
                                                    <>
                                                        <div className='ml-2'> {opcional.descricaogrupo} </div>
                                                        <div key={index} className='flex p-2 shadow ml-1 mr-1'>
                                                            <div className='w-14 grid place-items-center'>{'qtd: ' + opcional.quantidade}</div>
                                                            <div className='w-full grid justify-end'>{opcional?.DESCRICAO?.toLowerCase()}</div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {dadosItem?.tamanhoProduto && (
                                        <div className='flex w-full text-lg'>
                                            <div className='w-28 bg-[#003A18] text-white mt-2 p-2 rounded-r'> {t('tamanho').toUpperCase()} : </div>
                                            <div className='ml-3 grid place-items-center mt-2 shadow p-2'> {dadosItem?.tamanhoProduto?.TAMANHO} </div>
                                        </div>
                                    )}
                                    {dadosItem?.retirados?.length > 0 && (
                                        <div>
                                            <div className='w-28 bg-[#003A18] text-white mt-2 p-2 rounded-r'>{t('removidos').toUpperCase()}</div>
                                                {dadosItem?.retirados?.map((remove, index) => (
                                                    <div key={index}> - {remove.DESCRICAO}</div>
                                                ))}
                                        </div>
                                    )}
                                    {dadosItem?.observacoes && (
                                        <>
                                            <div className='w-36 bg-[#003A18] text-white mt-2 p-2 rounded-r'> {t('observacao').toUpperCase()} </div>
                                            <div className='m-2 p-2 shadow'> {dadosItem.observacoes} </div>
                                        </>
                                    )}
                                </div>
                                <div className='flex justify-between mt-3'>
                                    <button onClick={handleFechar} className='bg-red-400 m-1 pl-1 pr-1 text-white rounded'> {t('fechar').toUpperCase()} </button>
                                    <button className='flex place-items-center' onClick={handleAddOP} >
                                        <div className='text-green-600'> {t('editar').toUpperCase()} </div>
                                        <AiFillPlusSquare size={50} color='green'/>    
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
            { modalEditarOpcionais && <ModalOpcionais modalOpcionais={modalEditarOpcionais} setModalOpcionais={setModalEditarOpcionais} cartmodal={true} itemcart={item} /> }
            { opcionaispizza && <ModalOpcionaisPizza setOpcionaispizza={setOpcionaispizza} cartmodal={true} itemcart={item} /> }
        </>
    );
}
