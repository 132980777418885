import { conexao } from "../../conexao";
import { JanelaMensagem } from "../../../components/funcoes/funcoes";

export const itensPedido = async (tipo, id) =>{
    try {
        const getdata = await conexao.get(`/listaItensPedidoSalao/${tipo}/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getinfoComanda = async (id) =>{
    try {
        const getdata = await conexao.get(`/infoComanda/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getstatusmesa = async (numerocomanda) =>{
    try {
        const getdata = await conexao.get(`/statusmesa/${numerocomanda}`)
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            return dados.STATUS;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}