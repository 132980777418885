import React, { createContext, useState, useRef, useContext } from 'react';
import { imagemProduto } from '../api/chamados/get/produtos';

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartRef, setCartRef] = useState(null);
  const shoppingCartRef = useRef(null);

  const setRef = (ref) => {
    setCartRef(ref);
    shoppingCartRef.current = ref;
  };

  // Função de animação do carrinho
  const cartanimationAdd = async (item) => {
    const shopping_cart = cartRef;
  
    // Check if cartRef is null before proceeding
    if (!shopping_cart) {
      return;
    }
  
    const img = await imagemProduto(item?.produto?.ID_PRODUTO);
    if (img) {
      // Add the 'active' class to the cart
      shopping_cart.classList.add('active');
  
      // Create an image for the animation
      let flying_img = document.createElement('img');
      flying_img.src = `data:image/png;base64,${img}`;
      flying_img.classList.add('flying-img');
  
      // Set the size of the image
      flying_img.style.width = '90px';
      flying_img.style.height = '90px';
  
      // Set the initial position of the image in the center of the screen
      flying_img.style.cssText = `
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: transform 1s ease;
        z-index: 9999;
        border-radius: 50%;
      `;
  
      // Insert the image into the DOM
      document.body.appendChild(flying_img);
  
      // Use requestAnimationFrame to ensure the image is on the screen
      requestAnimationFrame(() => {
        const flying_img_pos = flying_img.getBoundingClientRect();
        const shopping_cart_pos = shopping_cart.getBoundingClientRect();
  
        // Adjust the initial position to be more centered over the cart
        const offsetX = shopping_cart_pos.width / 2 - flying_img_pos.width / 2 - 50;
        const offsetY = shopping_cart_pos.height / 2 - flying_img_pos.height / 2 - 60;
  
        // Calculate the distance the image needs to move
        let data = {
          left: shopping_cart_pos.left + offsetX - flying_img_pos.left,
          top: shopping_cart_pos.top + offsetY - flying_img_pos.top
        };
  
        // Apply animation styles with the calculated transformation
        flying_img.style.transform = `translate(${data.left}px, ${data.top}px) scale(0.5)`;
  
        // Remove the flying image after the transition
        setTimeout(() => {
          document.body.removeChild(flying_img);
          shopping_cart.classList.remove('active');
        }, 1000);
      });
    }
  };
  

  return (
    <CartContext.Provider value={{ cartRef, setRef, cartanimationAdd }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
