import { useState, useEffect } from 'react'
import './style.css'
import Decimal from 'decimal.js';
import { t } from 'i18next'
import { itensPedido } from '../../api/chamados/get/pedidos';
import { formatarValor } from '../../components/funcoes/funcoes';
import BarraPedidos from './barraPedidos'
import { getvalorpago } from '../../api/chamados/post/pedidos';

export default function PedidosCorpo(){
    const [dadosCompraPedido, setDadosCompraPedido] = useState([]);
    const [totalPedido, setTotalPedido] = useState(0);
    const [valorpago, setValorpago] = useState(0);
    const [atualizar, setAtualizar] = useState(false);
    const tipoComanda = localStorage.getItem('tipoComanda');
    const numeroComanda = localStorage.getItem('numeroComanda')

    useEffect(()=>{
        handleListaItensPedido()
        if(tipoComanda === "CARTAO"){
            handleInfoComanda()        
        }
    }, [atualizar])

    const handleListaItensPedido= async ()=>{
        const dados = await itensPedido(tipoComanda, numeroComanda)
            setDadosCompraPedido(dados)
            setAtualizar(false);
    }

    useEffect(() => {
        if(dadosCompraPedido?.length > 0){
            totalpedido()
            handlevalorjapago()
        }
    }, [dadosCompraPedido]);

    const handlevalorjapago = async () => {
        const result = await getvalorpago(tipoComanda, numeroComanda);
        let total = new Decimal(0); 
        result?.forEach(item => {
            total = total.plus(new Decimal(item.VALOR)); 
        });
        setValorpago(total)
    };

    const totalpedido = async ()=>{
        let total = new Decimal(0);
        dadosCompraPedido.forEach(item => {
            total = total.plus(new Decimal(item.VPROD_C_DESC || 0))
        });
        setTotalPedido(total);
    }

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'pedidocardark' : 'pedidocard'

    const boxShadowClass2 = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
        <div>
            <div className={`${boxShadowClass} bg-[#003A18] text-white flex justify-between p-3`}>
                    <div className='cardMargin grid place-items-center' > {tipoComanda} </div>
                    <div className='cardMargin text-xl'> {numeroComanda}  </div>
            </div>
            <div className={`${boxShadowClass2} quadroDetalhesPedido mt-10 m-2`}>
                <div className='detalhesPedidoTitulo'> {t('items').toUpperCase()} </div>
                <div className='detalhesPedidoBox'> 
                    <div className='detalhesPedidoQtd'> {t('qtd').toUpperCase()} </div>
                    <div className='detalhesPedidoDesc'> {t('descrição').toUpperCase()} </div>
                    <div className='detalhesPedidoValor'> {t('valor').toUpperCase()} </div>
                </div>
                <div style={{ maxHeight: '55vh', overflow: 'auto' }}>
                {
                    dadosCompraPedido?.map((item, index)=>
                        <div key={index} className='pedidoItemCard' >
                            <div className='itemCardLinha'>
                                <div className='itemPedidoQTD'> {item.QTDE_COM} </div>
                                <div className='itemPedidoDesc'> {item.DESCRICAO} </div>
                                <div className='itemValor'>
                                    {formatarValor(item.VPROD_C_DESC)}  
                                </div>
                            </div>
                            {item?.SABORES? (
                                <div className='itemCardLinha textOpcional'>
                                    <div className='itemCardIcone'>
                                    </div>
                                    <div className='itemCardOpcoes'>
                                        {item.SABORES? (
                                            <div className='itemSabores'> {item?.SABORES} </div>
                                        ) : null}
                                        <div className='itemOpcionais'>
                                            <div className='nomeOpcional'> {item?.OPCOES} </div>
                                        </div>
                                        <div className='itemObservacoes'> {item.OBSERVACOES} </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        )
                    }
                </div>
            </div> 
            <div className='pb-32'></div>
            <div className='grid place-items-center mt-10 fixed bottom-20 w-full'>
                <BarraPedidos totalPedido={totalPedido} valorpago={valorpago} setAtualizar={setAtualizar} />
            </div>
        </div>
    )
}