import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptTranslation from './locales/ptbr.json';
import esTranslation from './locales/esar.json';
import enTranslation from './locales/en.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { idioma } from '../api/chamados/get/empresa';

const savedLanguage = localStorage.getItem('i18nextLng');
const idiomadefault = await idioma()

let userLanguage = idiomadefault?.IDIOMA_PADRAO.trim() === 'Portugues - PTBR' 
  ? 'pt-BR' 
  : idiomadefault?.IDIOMA_PADRAO.trim() === 'Espanhol' 
  ? 'es' 
  : 'pt-BR';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'pt-BR',
    lng: userLanguage, 
    detection: {
      order:  ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'querystring', 'sessionStorage', 'subdomain'],
    },
    resources: {
      'en': {
        translation: enTranslation,
      },
      'pt-BR': {
        translation: ptTranslation,
      },
      'pt-PT': {
        translation: ptTranslation,
      },
      'pt': {
        translation: ptTranslation,
      },
      'es': {
        translation: esTranslation,
      },
      'es-419': {
        translation: esTranslation,
      },
      'es-CL': {
        translation: esTranslation,
      },
      'es-ES': {
        translation: esTranslation,
      },
      'es-MX': {
        translation: esTranslation,
      },
    },
  });

const supportedLanguages = Object.keys(i18n.options.resources);
if (!supportedLanguages.includes(userLanguage)) {
  i18n.changeLanguage(i18n.options.fallbackLng);
}
