import { conexao } from "../../conexao";
import { JanelaMensagem } from '../../../components/funcoes/funcoes'

export const pegarDadosEmpresa = async () =>{
    try {
        const getdata = await conexao.get(`/dadosEmpresa`)
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const descript = async (palavra) =>{
    const senha = 'edsfdgsASDF#@SDA24554'
    try{
        const descript = await conexao.get(`/EncryptDecrypt/${palavra}/'D'/${senha}`)
            return descript.data
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const encript = async (palavra) =>{
    const senha = 'edsfdgsASDF#@SDA24554'
    try{
        const encript =  await conexao.get(`/EncryptDecrypt/${palavra}/'E'/${senha}`)
            return encript
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const decriptRota = async (palavra) =>{
    const senha = 'tomamoscafevaitomanocu3w4563w4532'
    try{
        const descript = await conexao.get(`/descript/${palavra}/${senha}`)
            return descript.data
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const verificarCaixa = async () =>{
    try {
        const getdata = await conexao.get(`/verificarCaixa`)
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const idioma = async () =>{
    try{
        const getdata = await conexao.get('/MoedaAtiva')
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : []
        return dados;
    } catch(error){

    }
}

export const moedaAtiva = async () =>{
    try{
        const getdata = await conexao.get('/MoedaAtiva')
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : []
        const moeda = dados?.IDIOMA_PADRAO === 'Portugues - PTBR' ? 'BRL' : 'ARS'
            return moeda;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const parametrosCardapio = async () =>{
    try{
        const getdata = await conexao.get('/parametrosCardapio')
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            localStorage.setItem('darkmode', (dados?.MODO_EXIBICAO === '1 - CLARO' ? 'light' : 'dark'))
            localStorage.setItem('coriconesubgrupo', '#' + dados?.COR_ICONE)
            localStorage.setItem('corfonte', '#' + dados?.COR_FONTE)
            localStorage.setItem('corvalor', '#' + dados?.COR_PRECO)
            localStorage.setItem('corbarramenu', '#' + dados?.COR_BARRA_MENU)
            localStorage.setItem('coriconemenu', '#' + dados?.COR_FONTE_BARRA_MENU)
            localStorage.setItem('coriconemenuapagado', '#' + dados?.COR_FONTED_BARRA_MENU)
            localStorage.setItem('imglogo', getdata?.data[0]?.IMAGEMLOGO)
            localStorage.setItem('tipoheader', getdata.data[0].TIPO_CABECALHO)
            localStorage.setItem('corheader', '#' + dados?.COR_CAB_ROD)
            localStorage.setItem('imgheader', getdata?.data[0]?.IMGFUNDOHEADER)
            localStorage.setItem('logopos', getdata?.data[0]?.LOGO_POSICAO)
            const fonte = dados?.FONTE_CARDAPIO === 'Roboto' ? '"Roboto", sans-serif' :
                        dados?.FONTE_CARDAPIO === 'Montserrat' ? '"Montserrat", sans-serif' :
                        dados?.FONTE_CARDAPIO === 'Barlow' ? '"Barlow", sans-serif' :
                        '"Sofadi One", system-ui'
            localStorage.setItem('fontecardapio', fonte)
            return dados;
    } catch (error) {
        JanelaMensagem('error', 'vermelho')
    }
}

export const getdadosempresa = async ()=>{
    try{
        const getdata = await conexao.get('/dadosEmpresa')
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : []
        return dados;
    } catch(error){
        return null;
    }
}

export const getcidadesdelivery = async ()=>{
    try{
        const getdata = await conexao.get('/cidadesdelivery')
        const dados = Array.isArray(getdata.data) ? getdata.data : []
        return dados;
    } catch(error){
        return null;
    }
}

export const getbairrosdelivery = async (id)=>{
    try{
        const getdata = await conexao.get(`/bairrosdelivery/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
        return dados;
    } catch(error){
        return null;
    }
}

export const getestadodelivery = async (id)=>{
    try{
        const getdata = await conexao.get(`/estadodelivery/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : []
        return dados;
    } catch(error){
        return null;
    }
}