import { conexao } from "../../conexao";
import { JanelaMensagem } from '../../../components/funcoes/funcoes'

export const getlistaTamanhosPizza = async () =>{
    try {
        const getdata = await conexao.get(`listaTamanhosPizza`)
        const dados = Array.isArray(getdata.data) ? getdata.data : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getlistaSaboresPizza = async (id) =>{
    try {
        const getdata = await conexao.get(`/listaSaboresPizza/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const listaTamanhosProduto = async (id) =>{
    try {
        const getdata = await conexao.get(`listaTamanhosProdutos/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const ModoPreparo = async (id) =>{
    try {
        const getdata = await conexao.get(`/ModoPreparoProduto/${id}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getGrupoOpcionais = async (id, idTamanho, isPizza) => {
    try {
        const getdata = await conexao.get(`/listaGrupoOpcionais/${id}/${idTamanho}/${isPizza}`);
        const dados = Array.isArray(getdata.data) ? getdata.data : [];
        return dados;
    } catch (error) {
        JanelaMensagem('error', 'vermelho');
        //console.log(error.message)
    }
};

export const getGrupoOpcionaisAtrasados = async (id, idTamanho) =>{
    try {
        const getdata = await conexao.get(`/listaGrupoOpcionais/${id}/${idTamanho}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
        if (dados) {
            // Filtra os itens onde ATRASADO é 'SIM'
            const dadosFiltrados = dados.filter(item => item.ATRASADO === 'SIM');
            return dadosFiltrados;
        }
        return null;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getListOpcionais = async (id, idProduto, pizza) =>{
    try {
        const getdata = await conexao.get(`/listaOpcionais/${id}/${idProduto}/${pizza}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const fichaTecnica = async (id, idTamanho) =>{
    try {
        const getdata = await conexao.get(`/FichaTecnica/${id}/${idTamanho}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : null
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getFichaTecnica = async (id, idTamanho) =>{
    try {
        const getdata = await conexao.get(`/fichaTecnica/${id}/${idTamanho}`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const imagemProduto = async (id) =>{
    try{
        const getdata = await conexao.get(`/imagemProduto/${id}`)
            return getdata.data;
    }catch(error){
        //JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getPromocoes = async ()=>{
    try{
        const getdata = await conexao.get(`/promocoes`)
        const dados = Array.isArray(getdata.data) ? getdata.data : []
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getValorCusto = async (id, idGrade)=>{
    try{
        const getdata = await conexao.get(`/valorCusto/${id}/${idGrade}`)
            return getdata.data
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getSubgrupos = async (id, idGrade)=>{
    try{
        const getdata = await conexao.get(`/subgrupos`)
        const sortedData = getdata.data.sort((a, b) => {
            if (a.ORDEM === null || a.ORDEM === undefined) return 1;
            if (b.ORDEM === null || b.ORDEM === undefined) return -1;
            return a.ORDEM - b.ORDEM;
        });
            return sortedData
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getProdutos = async (idsubgrupo)=>{
    try{
        const getdata = await conexao.get(`/produtos/${idsubgrupo}`)
        const lista = Array.isArray(getdata.data) ? getdata.data : []
            return lista.sort((a, b) => a.PRODUTO.localeCompare(b.PRODUTO));
    }catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getDescricaoProduto = async (itemID)=>{
    try{
        const getdata = await conexao.get(`/descricaoProduto/${itemID}`)
            return getdata.data
    }catch(error){
        //JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getimgsproduto = async (id)=>{
    try{
        const getdata = await conexao.get(`/imgsproduto/${id}`)
            return getdata.data.imagens
    }catch(error){
        //JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const gettamanhopizza= async (id)=>{
    try{
        const getdata = await conexao.get(`/tamanhopizza/${id}`)
        return getdata.data[0]
    } catch(error){
        //JanelaMensagem('error', 'vermelho')
    }
}

export const gettamanhoproduto= async (id)=>{
    try{
        const getdata = await conexao.get(`/tamanhoproduto/${id}`)
        return getdata.data[0]
    } catch(error){

    }
}

export const getproduto= async (id)=>{
    try{
        const getdata = await conexao.get(`/produto/${id}`)
        return getdata.data[0]
    } catch(error){

    }
}

export const getsabor= async (id)=>{
    try{
        const getdata = await conexao.get(`/saborpizza/${id}`)
        return getdata.data
    } catch(error){

    }
}

export const getopcionais= async (id)=>{
    try{
        const getdata = await conexao.get(`/opcionais/${id}`)
        return getdata.data
    } catch(error){

    }
}

export const getretirados= async (id)=>{
    try{
        const getdata = await conexao.get(`/retirados/${id}`)
        return getdata.data
    } catch(error){

    }
}