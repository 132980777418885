import { useTranslation } from 'react-i18next';

export default function Observacoes({ setObservacao }){
    const {t} = useTranslation();

    return(
        <div className='observacoesCart'>
            <textarea className='observacoesCarttext text-xl' placeholder={t('observacoes') + "..."} onChange={(e) => setObservacao(e.target.value)}></textarea>
        </div>
    )
} 