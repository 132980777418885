import PedidosCorpo from './pedidosCorpo'
import Pedidosdelivery from './pedidosdelivery';
import './style.css'
import { useState, useEffect } from 'react'

export default function PedidoCartaoMesa(){
    const [ativo, setAtivo] = useState(false);

    useEffect(()=>{
      const res = localStorage.getItem('cardapiosimples') === "SIM" ? false : true
        setAtivo(res)
    }, [])

    return(
        <div>
            {
                ativo ? <PedidosCorpo/> : <Pedidosdelivery/>
            }
        </div>
    )
}