import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header"
import { useTranslation } from "react-i18next";
import { TbTrashXFilled } from "react-icons/tb";
import { removerPedido } from "../../redux/pedidosSlice";
import { getopcionais, getproduto, getretirados, getsabor, gettamanhopizza, gettamanhoproduto } from "../../api/chamados/get/produtos";
import Decimal from "decimal.js";
import { addToCart } from '../../redux/cartSlice'
 
export default function Pedidosdelivery(){
    const pedidos = useSelector((state) => state.pedidos.pedidos);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handlePedirNovamente = async (idpedido) => {
        const pedido = pedidos.find(item => item.id === idpedido);
        if (!pedido) {
            return;
        }
        for (const item of pedido.itens) {
            const produto = item.mista === 'NAO' 
                ? await getproduto(item.idproduto) 
                : '';
            const tamanhoPizza = item.mista === 'SIM' 
                ? await gettamanhopizza(item.idtamanho) 
                : '';
            const tamanhoProduto = item.mista === 'NAO' && item.tamanhodesc 
                ? await gettamanhoproduto(item.idgrade) 
                : '';
            const opcionais = item.opcionais
                ? await getOpcionaisForItems(item.opcionais)
                : [];
            const sabores = item.sabores
                ? await getSaboresForItems(item.sabores)
                : [];
            const retirados = item.retirados
                ? await getRetiradosForItems(item.retirados)
                : [];
            const total = item.mista === 'SIM' ? calculartotalpizza(tamanhoPizza, sabores, opcionais) : calculartotalproduto(produto, tamanhoProduto, opcionais)
            const totalOP = calculartotalop(opcionais)
            const cartitem = {
                mista: item.mista,
                observacoes: item.observacao,
                opcionais,
                produto,
                quantity: item.quantidade,
                sabores,
                tamanhoPizza,
                tamanhoProduto,
                retirados,
                total,
                totalOP,
            }
            await dispatch(addToCart(cartitem));
        }
    };    

    async function getOpcionaisForItems(opcionaisList) {
        const allOpcionais = [];
    
        for (const opcional of opcionaisList) {
            const fetchedOpcionais = await getopcionais(opcional.id);
            const fetchedOpcionaisnew = fetchedOpcionais.map(item => ({
                ...item,
                quantidade: opcional.quantidade,
                descricaogrupo: item.GRUPODESC,
                grupoUnicoId: opcional.grupoUnicoId,
                inicial: ''
            }));
            allOpcionais.push(...fetchedOpcionaisnew);
        }
    
        return allOpcionais;
    }

    async function getSaboresForItems(sabores) {
        const allSabores = [];
    
        for (const sabor of sabores) {
            const fetchedSabores = await getsabor(sabor.id);
            const fetchedSaboresnew = fetchedSabores.map(item => ({
                ...item,
                quantidade: sabor.quantidade
            }));
            allSabores.push(...fetchedSaboresnew);
        }
    
        return allSabores;
    }

    async function getRetiradosForItems(retirados){
        const allRetirados = [];
    
        for (const retirado of retirados) {
            const fetchedRetirados = await getretirados(retirado.ID);
            allRetirados.push(...fetchedRetirados);
        }
    
        return allRetirados;
    }

    const calculartotalproduto =(produto, tamanhoProduto, opcionais)=>{
        let total = tamanhoProduto? new Decimal(tamanhoProduto.VALOR_VENDA) : new Decimal(produto.VALOR_VENDA)
        opcionais.forEach(opcional => {
            total = total.plus(new Decimal(opcional.VALOR_VENDA || 0).times(opcional.quantidade || 1));
        });
        return total.toFixed(2)
    }

    const calculartotalpizza = (tamanhoPizza, sabores, opcionais) => {
        let total = new Decimal(0);
        const totalQuantity = sabores.reduce((sum, item) => sum + (item.quantidade || 0), 0);    
        let totalItem = new Decimal(0);

        if (tamanhoPizza.VALOR_MAIOR_MISTA === "NAO") {
            totalItem = sabores.reduce((acc, item) => {
                const valorVenda = new Decimal(item.VALOR_VENDA || 0);
                const quantidade = new Decimal(item.quantidade || 0);
                const multipliedValue = valorVenda.times(quantidade);
    
                if (totalQuantity > 0) {
                    const dividedValue = multipliedValue.dividedBy(totalQuantity);
                    return acc.plus(dividedValue);
                } else {
                    return acc;
                }
            }, new Decimal(0));
        } else if (tamanhoPizza.VALOR_MAIOR_MISTA === "SIM") {
            totalItem = sabores.reduce((acc, item) => {
                const valorVenda = new Decimal(item.VALOR_VENDA || 0);
                const quantidade = new Decimal(item.quantidade || 0);
                const multipliedValue = valorVenda.times(quantidade);
                return acc.plus(multipliedValue);
            }, new Decimal(0));
        }

        opcionais.forEach(opcional => {
            total = total.plus(new Decimal(opcional.VALOR_VENDA || 0).times(opcional.quantidade || 1));
        });
    
        return totalItem.plus(total).toFixed(2);
    };

    const calculartotalop =(opcionais)=>{
        let total = new Decimal(0)
        opcionais.forEach(opcional => {
            total = total.plus(new Decimal(opcional.VALOR_VENDA || 0).times(opcional.quantidade || 1));
        });
        return total.toFixed(2)
    }

    const handleExcluir = (pedido) => {
        dispatch(removerPedido(pedido.id));
    };

    const data = (data)=>{
        const datanova = new Date(data);
        const formattedDate = datanova.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return formattedDate
    }

    const boxShadowClass2 = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
        <>
            <Header/>
            <div className="pb-14">
                <div className="pt-3 pb-1 ml-3 mr-3 border-b-2"> {t('pedidos')} </div>
                {
                    pedidos?.map((pedido, index)=>
                        <div className={`${boxShadowClass2} mt-2 mb-2 p-2`} key={index}>
                            <div className="flex">
                                <button className="grid place-items-center p-2" onClick={()=> handleExcluir(pedido)}> 
                                    <TbTrashXFilled  size={30} />
                                </button>
                                <div className="flex w-full justify-between place-items-center">
                                    <div> {t('dia')} : {data(pedido.id)} </div>                                
                                </div>
                            </div>
                            <div>
                                <div className="flex justify-between m-2 place-items-center">
                                    <div> {t('produtos')} </div>
                                    <button className="p-2 rounded bg-black text-white" onClick={()=> handlePedirNovamente(pedido.id)}> {t('pedirnovamente')} </button>
                                </div>
                                {pedido?.itens?.map((item, index)=>
                                    <div className="shadow m-2 p-2 rounded mt-1" key={index}>
                                        <div> {item.quantidade} - {item.descricao} {item.tamanhodesc ? "( " + item.tamanhodesc + " )" : ''} </div>
                                        {item?.sabores?.length > 0 && <div> {t('sabores')} </div>}
                                        { item?.sabores?.map((item, index) => 
                                            <div className="ml-5" key={index}> {item.quantidade} - {item.sabor} </div>
                                        )}
                                        {item.opcionais.length > 0 && <div> {t('opcionais')} </div>}
                                        { item.opcionais.map((opcional, index)=>
                                            <div className="ml-5" key={index}> {opcional.quantidade} - {opcional.descricao} </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            </div> 
        </>
    )
}