import { conexao } from "../../conexao";
import { JanelaMensagem } from "../../../components/funcoes/funcoes";

export const inserirPedido = async ( pedido ) =>{
    try {
        const getdata = await conexao.post(`/inserirPedido`, pedido)
        const dados = getdata?.data
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const inserirServico = async (data) =>{
    try {
        const getdata = await conexao.post(`/inserirServico`, data)
        const dados = getdata?.data
            return dados;
    } catch(error){
        JanelaMensagem('error', 'vermelho')
        //console.log(error.message)
    }
}

export const getvalorpago = async (tipoComanda, numeroComanda) =>{
    try{
        const getdata = await conexao.get(`/valorpago/${tipoComanda}/${numeroComanda}`)
        const dados = getdata?.data
        return dados;
    } catch (error){
    }
}