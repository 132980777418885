import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './tradutor/Tradutor';
import { decriptRota, parametrosCardapio } from './api/chamados/get/empresa';
import { t } from 'i18next';
import { verificatipocardapio } from './components/funcoes/funcoes';
import './style.css';

export default function TelaInicialCardapio() {
  const [tipoComanda, setTipoComanda] = useState(null);
  const [numeroComanda, setNumeroComanda] = useState(null);
  const [parametros, setParametros] = useState('');
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    handlepegarparametros();
    const criptTipoComanda = urlParams.get('00061d0e2e001e020f0204');
    const criptNumeroComanda = urlParams.get('1a1a00041f00300c0c070b1200');
    unCript(criptTipoComanda, criptNumeroComanda);
  }, []);

  const unCript = async (criptTipoComanda, criptNumeroComanda) => {
    try {
      const tipoComanda = await decriptRota(criptTipoComanda);
      setTipoComanda(tipoComanda === 'to' ? null : tipoComanda);
      const numeroComanda = await decriptRota(criptNumeroComanda);
      setNumeroComanda(numeroComanda === 'to' ? null : numeroComanda);
    } catch (error) {
      console.error("Erro ao descriptografar:", error.message);
    }
  };

  const handlepegarparametros= async ()=>{
    const res = await parametrosCardapio()
      setParametros(res)
  }

  const handleNavegar = () => {
    handleFullScreen()
    irParaMenu();
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const irParaMenu = async () => {
    localStorage.setItem('tipoComanda', tipoComanda);
    localStorage.setItem('numeroComanda', numeroComanda);
      await verificatipocardapio(tipoComanda, numeroComanda)
    navigate('/Main');
  };

  return (
    <div className='mainTelaInicial dark:bg-black' style={{ background: `${'#' + parametros?.COR_BARRA_MENU}`, color: `${'#' + parametros?.COR_FONTE_BARRA_MENU}` }}>
      <div className='boxtelainicial'>
        <div className='mt-12'>
          <div className='logoTelainicial'>
            <img src={localStorage.getItem('imglogo')} alt='Restaurante' className='img-restaurante h-48 rounded' style={{ borderRadius: '50%' }} />
          </div>
        </div>
        <div className='p-2 text-3xl mt-5'> {t('bemvindo')} ! </div>
        <div className='bg-green-600 h-12 mt-10 w-2/4 grid place-items-center text-white rounded-lg text-xl cursor-pointer' onClick={handleNavegar}> {t('continuar').toUpperCase()} </div>
        <div className='poweredby'>
          <div className=''>Powered By B&d Info Services.</div>
        </div>
      </div>
    </div>
  );
}
