import React, { useEffect, useState } from "react";
import { getGrupoOpcionais } from "../../api/chamados/get/produtos";
import { useTranslation } from "react-i18next";
import { removeItem, updateCartItem } from '../../redux/cartSlice';
import { useDispatch, useSelector } from "react-redux";
import Observacoes from "../../components/observacoes";
import Opcionais from "./opcionais";
import Decimal from 'decimal.js'
import './style.css'
import { AnimatePresence, motion } from 'framer-motion';
import { useCart } from '../../components/cartContext'

export default function ModalOpcionais({ opcionaispizza, setOpcionaispizza, cartmodal, itemcart }) {
    const cart = useSelector((state) => state.cart.cart);
    const item = cartmodal ? cart.find(item => item.id === itemcart.id) : cart?.[cart.length - 1];
    const [currentPage, setCurrentPage] = useState('loading');
    const [etapas, setEtapas] = useState([]);
    const dispatch = useDispatch();
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        verificarTelas();
    }, []);

    const verificarTelas = async () => {
        const possuiOpcional = await getGrupoOpcionais(0, item?.tamanhoPizza?.ID, true);        
        const novasEtapas = [
            possuiOpcional?.length > 0 && 'opcionais',
            'observacoes'
        ].filter(Boolean);    

        setEtapas(novasEtapas);
        navegarParaProximaEtapa(novasEtapas, 0);
    };

    const navegarParaProximaEtapa = (etapas, index) => {
        if (index < etapas.length) {
            setCurrentPage(etapas[index]);
        } 
    };

    const handleCancelar = () => {
        if(cartmodal){
            setOpcionaispizza(false);
        } else {
            dispatch(removeItem(item.id));
            setOpcionaispizza(false);
        }
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    React.useEffect(() => {
        document.body.style.overflow = opcionaispizza ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [opcionaispizza]);

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return (
        <AnimatePresence>
            {!isClosing && (
                <>
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={overlayVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    />
                    <motion.div
                        className="modal-container"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    >
                        <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black`} style={{ width: '97%' }}>
                            {currentPage === 'opcionais' && (
                                <OpcionaisStep 
                                    onCancel={handleCancelar}
                                    item={item}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                            {currentPage === 'observacoes' && (
                                <ObservacaoStep 
                                    onCancel={handleCancelar}
                                    item={item}
                                    setIsClosing={setIsClosing}
                                    setOpcionaispizza={setOpcionaispizza}
                                />
                            )}
                            {currentPage === 'loading' && (
                                <div className='grid place-items-center' style={{ minHeight: '300px' }}>
                                    
                                </div>
                            )}
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
}

function OpcionaisStep({ onCancel, item, setCurrentPage }) {
    const { t } = useTranslation();    
    const [grupos, setGrupos] = useState([]);
    const [newListSelect, setNewListSelect] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(item.opcionais.length > 0){
            setNewListSelect(item.opcionais)
        }
    }, [])
    const handleFinalizar = async () => {
        const updatedOpcionais = [...newListSelect];
        const newTotalOP = updatedOpcionais.reduce((sum, data) => {
            return sum.plus(new Decimal(data.VALOR_VENDA).times(data.quantidade));
        }, new Decimal(0));        
        const totalOP = newTotalOP.plus(new Decimal(item.totalOP));
        const updatedItem = {
            ...item,
            opcionais: updatedOpcionais,
            total: new Decimal(item.total).plus(totalOP).toFixed(2),
            totalOP: totalOP.toFixed(2)
        };
        dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));
        setCurrentPage('observacoes');
    };

    const handleNextGroup = () => {
        if (currentIndex < grupos.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleBackGroup = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <>
            <Opcionais item={item} grupos={grupos} setGrupos={setGrupos} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} newListSelect={newListSelect} setNewListSelect={setNewListSelect}/>
            <div className="p-2 flex place-items-center justify-between flex-wrap">
                {
                    currentIndex > 0 ? (
                        <button className="p-3 m-2 bg-blue-400 text-white rounded-lg w-[100px]" onClick={handleBackGroup}> {t('voltar').toUpperCase()} </button>
                    ) : null
                }
                    <button className="p-3 m-1 bg-red-500 text-white rounded-lg w-[100px]" onClick={onCancel}> {t('cancelar').toUpperCase()} </button>
                {
                    currentIndex === grupos.length -1 ? (
                        <button className="p-2 m-1 bg-black text-white rounded-lg w-[100px] dark:bg-white dark:text-black" onClick={handleFinalizar}> {t('addnocart').toUpperCase()} </button>
                    ) : (
                        <button className="p-3 m-2 bg-blue-400 text-white rounded-lg w-[100px]" onClick={handleNextGroup}> {t('proximo').toUpperCase()} </button>
                    )
                }
            </div>
        </>
    );
}

function ObservacaoStep({ onCancel, item, setIsClosing, setOpcionaispizza }) {
    const { t } = useTranslation();
    const [observacao, setObservacao] = useState('');
    const dispatch = useDispatch();
    const { cartanimationAdd } = useCart();
    
    const addobs = () => { 
        const updatedItem = {
            ...item,
            observacoes: observacao 
        };
        dispatch(updateCartItem({ itemId: item.id, updatedData: updatedItem }));      
        setIsClosing(true); // Ativa o estado de fechamento
        setTimeout(() => {
            setOpcionaispizza(false); // Fecha o modal após a animação de saída
        }, 600); // Duração igual ao tempo da animação de saída
        //cartanimationAdd(item)
    }; 

    return (
        <div>
            <div className="grid place-items-center text-xl p-2" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}> {t('observacao')} </div>
                <div className="h-40">
                    <Observacoes setObservacao={setObservacao} />
                </div>
            <div className="flex justify-between">
                <button className="p-3 m-2 bg-red-400 text-white rounded" onClick={onCancel}> {t('cancelar').toUpperCase()} </button>
                <button className="p-3 m-2 bg-black text-white rounded dark:bg-white dark:text-black" onClick={addobs}> {t('addnocart').toUpperCase()} </button>
            </div>
        </div>
    );
}

function LoadCart({ setOpcionaispizza }){
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setInterval(() => {
          const d = new Date(),
            seconds = d.getMinutes() * 60 + d.getSeconds(),
            totalTime = 60 * 6,
            tL = totalTime - (seconds % totalTime),
            r = `${parseInt(tL / 60, 10)}:${tL % 60}`;
         handleNavegar()
        }, 2000);
    return () => clearInterval(timer);
    }, []);

    const handleNavegar=()=>{
        setOpcionaispizza(false)
    }

    return(
        <div className="h-full w-full h-20 p-5 rounded bg-[#003A18] text-white grid place-items-center"> 
            <div className="text-2xl"> {t('iteminserido').toUpperCase()} </div>
        </div>
    )
}