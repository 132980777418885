import { useEffect, useState } from 'react'
import { formatarValor } from '../../components/funcoes/funcoes';
import './style.css'
import { BsPatchCheckFill, BsFillPatchMinusFill } from "react-icons/bs";
import { listaTamanhosProduto } from '../../api/chamados/get/produtos';

export default function GrupoTamanho({ tamanhoEscolhido, produto, setTamanhoEscolhido }){
  const [grupoTamanho, setGrupoTamanho] = useState(null);

  useEffect(()=>{
    handleListaTamanhos()
  }, []);

  const handleListaTamanhos= async ()=>{
    const dados = await listaTamanhosProduto(produto.ID_PRODUTO)
      setGrupoTamanho(dados)
  }

  const RadioTamanhos = (item, index) => {
    setTamanhoEscolhido(item)
  };

    return(
        <div>
          {
            grupoTamanho?.length > 0?(
              <div> 
                {
                  grupoTamanho?.map((item, index) => (
                    <div className={`cardTamanho flex m-1 h-12 place-items-center border rounded ${tamanhoEscolhido.ID === item.ID ? 'bg-green-200' : ''}`} key={index} onClick={() => RadioTamanhos(item, index)}>
                      <div className='boxtamanhos1'>
                        <div className='pl-2'>{item?.TAMANHO}</div>
                      </div>
                      <div className='boxtamanhos2'>
                        <div> {formatarValor(item.VALOR_VENDA)} </div>
                      </div>
                      <div className='CardIcon grid place-items-center mr-1'>
                        <input type='radio' name='tamanhos' checked={tamanhoEscolhido.ID === item.ID} onChange={() => {}}/>
                          {tamanhoEscolhido.ID === item.ID
                            ? (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAqUlEQVR4nO2VQQrCMBBF36rewpNkETyTINKTWW/g2hO40xOYVUvpCCGQ1JlY3ORBNiHz/88EJtBQsgcGIACjcgWpnTWyXIET0GmTsdScxSRLMIp/2IlGlvmqtYzNIMYD961a5IAncNjCwGXE1QY3aUOMF/F032TgkqSl5CaDOPEReBWSV72BBx5fiJsNNPzXIPxg2L1LBwYZudZx3a+N69oP57L24TRImQB4HE1CkuADNAAAAABJRU5ErkJggg=="/>
                            )
                            : (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaklEQVR4nO2UQQqAMAwE93OKPyqogV6ifbYUcshFYQOKaAYCPUwZKDRAkjgmAA1AAbDZuZ2MOm8AQb8YQRl5CUZWRpYnIjUYqRlhqLfJjp9GJBiR1/14DUaUkUfbsLM9QbkYMW9nt3DycQ6q2hsLMO2nTAAAAABJRU5ErkJggg=="/>
                            ) 
                          }
                      </div>
                    </div>
                  ))
                }
              </div>
            ) : null
          } 
        </div>
    )

}