import { useTranslation } from "react-i18next"
import { getvalorpago, inserirServico } from "../../api/chamados/post/pedidos";
import { formatarValor, JanelaMensagem } from "../../components/funcoes/funcoes";
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from "react";
import { CiCircleCheck } from "react-icons/ci";
import { cancelarpix, consultarPix, gerarPixQR, getdadospix, inserirpago } from "../../api/chamados/post/pix";
import { QRCodeSVG } from "qrcode.react";
import { verificarCaixa } from "../../api/chamados/get/empresa";
import Decimal from "decimal.js";

export default function BarraPedidos({ totalPedido, valorpago, setAtualizar }){
    const { t } = useTranslation();
    const [modalGarcom, setModalGarcom] = useState(false);
    const [modalConta, setModalConta] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
      const calculateTotal = async () => {
        let total = new Decimal(totalPedido);
        let total2 = new Decimal(valorpago);
        const newtotal = total.minus(total2);
        setTotal(newtotal.toFixed(2)); 
      };      
      calculateTotal();
    }, [totalPedido, valorpago]);


    const handleGarcom= async ()=>{
        setModalGarcom(true)
    }
    const handleConta= async ()=>{
        setModalConta(true)
    }
    
    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : '';

    const boxShadowClass2 = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
        <div className="flex justify-between w-full pl-2 pr-2">             
            <button className={`${boxShadowClass} p-2 bg-[#003A18] text-white dark:bg-black w-[30%] h-[70px] rounded`} onClick={handleGarcom} >
                <div> {t('solicitarservico')} </div>
            </button>
            {
              total !== '0.00' && total !== 0 &&
                <button className={`${boxShadowClass} p-2 bg-[red] text-white dark:bg-black w-[30%] h-[70px] rounded mr-1`} onClick={handleConta}>
                  <div className="break-all"> {t('solicitarconta')} </div>
                </button>
            }
            {
                //<button className={`${boxShadowClass} p-2 bg-[red] text-white dark:bg-black w-[30%] h-[70px] rounded mr-1`} onClick={handleConta}>
                //    <div className="break-all"> {t('solicitarconta')} </div>
                //</button>
            }
            <div className={`${boxShadowClass2} grid place-items-center p-2 w-[30%] h-[70px] ml-1 rounded`}>
                <div> {t('Total')} </div>
                <div>
                    {formatarValor(totalPedido - valorpago)} 
                </div>
            </div> 
            { modalGarcom && <ModalGarcom setModalGarcom={setModalGarcom} /> }
            { modalConta && <ModalConta totalPedido={totalPedido} setModalConta={setModalConta} setAtualizar={setAtualizar} /> }
        </div>
    )
}

function ModalGarcom({ setModalGarcom }){
    const tipoComanda = localStorage.getItem('tipoComanda')
    const numeroComanda = localStorage.getItem('numeroComanda')
    const [isClosing, setIsClosing] = useState(false);
    const { t } = useTranslation();
  
    const overlayVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
      exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };
  
    const handleserv= async ()=>{
      const dados = {
        numerocomanda: numeroComanda,
        tipocomanda: tipoComanda,
        tipo: 'GERAL'
      }
      if(tipoComanda && numeroComanda){
        const res = await inserirServico(dados)
        if(res === 'ok'){
            const mensagem = t('garcomchamado')
            JanelaMensagem(mensagem, 'verde')
            fechar()
        }
      }
    }

    const fechar=()=>{
      setIsClosing(true); // Ativa o estado de fechamento
      setTimeout(() => {
        setModalGarcom(false); // Fecha o modal após a animação de saída
      }, 600);
    }
  
    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return(
      <AnimatePresence>
        {!isClosing && (
          <>
            <motion.div
              className="overlay"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={overlayVariants}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            />
            <motion.div
              className="modal-container"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <div className={`${boxShadowClass} modal bg-white rounded dark:bg-black`} style={{ width: '97%' }}>
                <div className="grid place-items-center p-5 text-xl"> {t('confirmargarcom')} </div>      
                <div className="flex place-items-center justify-between m-3">
                  <button className="p-3 rounded bg-red-500 text-white" onClick={fechar}> {t('cancelar').toUpperCase()} </button>
                  <button className="p-3 rounded bg-black text-white dark:bg-white dark:text-black" onClick={handleserv}> {t('chamar').toUpperCase()} </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    )
}

function ModalConta({ totalPedido, setModalConta, setAtualizar }){
    const [isClosing, setIsClosing] = useState(false);
    const { t } = useTranslation();    
    const tipoComanda = localStorage.getItem('tipoComanda')
    const numeroComanda = localStorage.getItem('numeroComanda')
  
    const overlayVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
      exit: { opacity: 0 }
    };
      
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.4, borderRadius: "50%" }, 
        visible: { opacity: 1, scale: 1, borderRadius: "0%" }, 
        exit: { 
            opacity: 0, 
            scale: 0.4, 
            borderRadius: "50%", 
            transition: { duration: 0.4, ease: "easeOut" }
        }
    };

    const fechar=()=>{
      setIsClosing(true)
      setAtualizar(true)
      setTimeout(() => {
        setModalConta(false); // Fecha o modal após a animação de saída
      }, 600);
    }
    
    const handleaddserv= async ()=>{
      const dados = {
        numerocomanda: numeroComanda,
        tipocomanda: tipoComanda,
        tipo: 'CONTA'
      }
      if(tipoComanda && numeroComanda){
        const res = await inserirServico(dados)
        if(res === 'ok'){
            const mensagem = t('garcomchamado')
            JanelaMensagem(mensagem, 'verde')
            fechar()
        }
      }
    }

    return(
      <AnimatePresence>
        {!isClosing && (
          <>
            <motion.div
              className="overlay grid"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={overlayVariants}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            />
            <motion.div
              className="modal-container"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <div className="modal bg-white rounded dark:bg-black" style={{ width: '97%', maxWidth: 400 }}>
                <div className="grid place-items-center p-5 text-xl text-black"> {t('confirmarconta')} </div>      
                {
                  //<div className="flex place-items-center m-1 justify-between">
                  //<button className="p-3 bg-red-500 rounded text-white" onClick={fechar}> {t('fechar').toUpperCase()} </button>
                  //<button className="p-3 bg-black rounded text-white" onClick={handleaddserv}> {t('pedirconta').toUpperCase()} </button>
                  //</div>
                }
                
                  <div> 
                    <Pagamentos totalPedido={totalPedido} fechar={fechar} />
                  </div>
                
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    )
}

function Pagamentos({ totalPedido, fechar }) {
    const tipoComanda = localStorage.getItem('tipoComanda')
    const numeroComanda = localStorage.getItem('numeroComanda')
    const { t } = useTranslation();
    const [selectedPayment, setSelectedPayment] = useState('');
    const [troco, setTroco] = useState(0);
    const [dadospix, setDadospix] = useState('');

    const handlePaymentChange = (e) => {
      setSelectedPayment(e.target.value);
    };
  
    useEffect(()=>{
        handledadospix()
    }, [])
    
    const handledadospix= async ()=>{
        const dados = await getdadospix()
            setDadospix(dados)
    }
  
    return (
      <div className="grid place-items-center">
        <div className="w-[95%] relative">
          <label className="absolute top-2 left-5 text-xl text-white"> {t('pix').toUpperCase()} </label>
          <input type="radio" name="pagamento" id="pix" value="pix" checked={selectedPayment === 'pix'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
          {selectedPayment === 'pix' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
        </div>
        <div className="w-[95%] relative">
          <label className="absolute top-2 left-5 text-xl text-white"> {t('cartao').toUpperCase()} </label>
          <input type="radio" name="pagamento" id="cartao" value="cartao" checked={selectedPayment === 'cartao'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
          {selectedPayment === 'cartao' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
        </div>
        <div className="w-[95%] relative">
          <label className="absolute top-2 left-5 text-xl text-white"> {t('dinheiro').toUpperCase()} </label>
          <input type="radio" name="pagamento" id="dinheiro" value="dinheiro" checked={selectedPayment === 'dinheiro'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
          {selectedPayment === 'dinheiro' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
        </div>
        <div className="w-[95%] relative">
          <label className="absolute top-2 left-5 text-xl text-white"> {t('outro').toUpperCase()} </label>
          <input type="radio" name="pagamento" id="outro" value="outro" checked={selectedPayment === 'outro'} className="w-full p-5 rounded" style={{ background: 'black' }} onChange={handlePaymentChange} />
          {selectedPayment === 'outro' && <div className="absolute top-1 right-5"> <CiCircleCheck size={30} color="green" /> </div>}
        </div>
        <div className="mt-5 w-full overflow-hidden">
          <AnimatePresence>
            {selectedPayment === 'pix' && <Pix dadospix={dadospix} totalPedido={totalPedido} fechar={fechar} tipoComanda={tipoComanda} numeroComanda={numeroComanda} />}
            {selectedPayment === 'cartao' && <Cartao fechar={fechar} tipoComanda={tipoComanda} numeroComanda={numeroComanda} />}
            {selectedPayment === 'dinheiro' && <Dinheiro troco={troco} setTroco={setTroco} fechar={fechar} tipoComanda={tipoComanda} numeroComanda={numeroComanda} />}
            {selectedPayment === 'outro' && <Outro fechar={fechar} tipoComanda={tipoComanda} numeroComanda={numeroComanda} />}
          </AnimatePresence>
        </div>
      </div>
    );
}
  
function Dinheiro({ troco, setTroco, fechar, tipoComanda, numeroComanda }) {
    const { t } = useTranslation();

    const handleTroco = (e) => {
        let valor = e.target.value.replace(/\D/g, '');
        let valorFormatado = (valor / 100).toFixed(2); 
        valorFormatado = valorFormatado
            .replace(".", ",") // Substitui o ponto decimal por vírgula
            .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona pontos nos milhares
        setTroco(valorFormatado);
    };

    const handleaddserv= async ()=>{
      const dados = {
        numerocomanda: numeroComanda,
        tipocomanda: tipoComanda,
        tipo: 'CONTA',
        mensagem: `Pagamento em dinheiro! TROCO ${troco}`
      }
      if(tipoComanda && numeroComanda){
        const res = await inserirServico(dados)
        if(res === 'ok'){
            const mensagem = t('garcomchamado')
            JanelaMensagem(mensagem, 'verde')
            fechar()
        }
      }
    }

    return (
      <motion.div
        className="p-4 bg-gray-100 rounded shadow w-full"
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <>
          <div className="relative mb-5">
              <div className="text-black"> {t('troco').toUpperCase()} </div>
              <label className="absolute top-9 left-2" style={{ color: localStorage.getItem('corvalor') }}> {localStorage.getItem('moeda') === 'BRL' ? 'R$' : '$'} </label>
              <input type="text" className="w-full p-3 pl-9 rounded shadow outline-none text-black" value={troco} onChange={handleTroco}/>
          </div>
          <div className="flex justify-between w-full">
            <button className="bg-red-500 p-5 rounded text-white" onClick={fechar}> {t('cancelar').toUpperCase()} </button>
            <button className="bg-black p-5 rounded text-white" onClick={handleaddserv}> {t('pagar').toUpperCase()} </button>
          </div>
        </>
      </motion.div>
    );
} 

function Cartao({ fechar, tipoComanda, numeroComanda }){
  const { t } = useTranslation();

  const handleaddserv= async ()=>{
    const dados = {
      numerocomanda: numeroComanda,
      tipocomanda: tipoComanda,
      tipo: 'CONTA',
      mensagem: 'Pagamento no cartão',
      pagamento: 'CARTAO'
    }
    if(tipoComanda && numeroComanda){
      const res = await inserirServico(dados)
      if(res === 'ok'){
          const mensagem = t('garcomchamado')
          JanelaMensagem(mensagem, 'verde')
          fechar()
      }
    }
  }

  return(
      <motion.div
        className="p-4 bg-gray-100 rounded shadow w-full"
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ type: "spring", stiffness: 100 }}
      >
          <div className="flex justify-between w-full">
            <button className="bg-red-500 p-5 rounded text-white" onClick={fechar}> {t('cancelar').toUpperCase()} </button>
            <button className="bg-black p-5 rounded text-white" onClick={handleaddserv}> {t('pagar').toUpperCase()} </button>
          </div>
      </motion.div>
  )
}

function Pix({ dadospix, totalPedido, fechar, tipoComanda, numeroComanda }) {
    const { t } = useTranslation();
    const [pixGerado, setPixGerado] = useState(false);
    const [pixQR, setPixQR] = useState('');
    const [pixTXID, setPixTXID] = useState('');
    const [status, setStatus] = useState('');
    const [caixabaerto, setCaixaAberto] = useState(false);
    const [qtddivisao, setQtddivisao] = useState('');
    const [valordivisao, setValordivisao] = useState(0);
    const [totalpagar, setTotalpagar] = useState(0);
    const modo = 'producao'
    
    useEffect(() => {
      const calculateTotal = async () => {
        if (qtddivisao === 'parcial') {
          setTotalpagar(valordivisao);
        } else {
          const result = await getvalorpago(tipoComanda, numeroComanda);
          let total = new Decimal(totalPedido);
          result.forEach(item => {
            total = total.minus(new Decimal(item.VALOR));
          });
          setTotalpagar(total.toFixed(2));
        }
      };
  
      calculateTotal();
    }, [qtddivisao, valordivisao, tipoComanda, numeroComanda, totalPedido]);

    useEffect(()=>{
      handlecaixa()
    }, [])

    const handlecaixa = async ()=>{
      const res = await verificarCaixa()
      if(res.STATUS === 'ABERTO'){
        setCaixaAberto(true)
      } else {
        return
      }
    }

    const gerarPix= async ()=>{
        const res = await gerarPixQR(dadospix, totalpagar, modo, tipoComanda, numeroComanda, qtddivisao)
        if(res){
          if(dadospix.PSPATUAL === 'Sicoob'){
            setPixQR(res.brcode)
          } else if(dadospix.PSPATUAL === 'BancoDoBrasil'){
            setPixQR(res.pixCopiaECola)
          }
          setPixTXID(res.txid)
          setPixGerado(true)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleConsultarPix();
        }, 2000);
        return () => clearInterval(intervalId);
    }, [pixGerado, pixTXID]);
  
    const handleConsultarPix= async()=>{
      if(pixTXID){
        const res = await consultarPix(dadospix, pixTXID, modo)
          setStatus(res.status)
      }
    }

    useEffect(()=>{
      if(status === 'CONCLUIDA'){
        JanelaMensagem(t('pagamentoconcluido'), 'verde')
        if(qtddivisao === 'parcial'){
          setPixGerado(false)
          setPixQR('')
          setPixTXID('')
          setStatus('')
          setQtddivisao('')
          setValordivisao('')
        } else {
          fechar()
        }
      } else if(status === 'REMOVIDA_PELO_USUARIO_RECEBEDOR' ){
        JanelaMensagem(t('pixcancelado'), 'amarelo')
        fechar()
      }
    }, [status])

    const handleaddserv= async ()=>{
      const dados = {
        numerocomanda: numeroComanda,
        tipocomanda: tipoComanda,
        tipo: 'CONTA',
        mensagem: 'Pagamento no pix!',
        troco: troco
      }
      if(tipoComanda && numeroComanda){
        const res = await inserirServico(dados)
        if(res === 'ok'){
            const mensagem = t('garcomchamado')
            JanelaMensagem(mensagem, 'verde')
            fechar()
        }
      }
    }

    const handlecancelar= async ()=>{
      if(pixGerado){
        const res = await cancelarpix(dadospix, pixTXID, modo)
      } else {
        fechar()
      }
    }

    const handlevalordivisao = (e) => {
      let valor = e.target.value.replace(/\D/g, '');
      let valorFormatado = (valor / 100).toFixed(2); 
      valorFormatado = valorFormatado
          .replace(".", ",") // Substitui o ponto decimal por vírgula
          .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona pontos nos milhares
        setValordivisao(valorFormatado);
    };

    const handletestepago= async ()=>{
      const res = await inserirpago(dadospix, totalpagar, modo, tipoComanda, numeroComanda, qtddivisao)
    }

    return (
      <motion.div
        className="p-4 bg-gray-100 rounded shadow w-full grid place-items-center"
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        {
          qtddivisao ? 
            <div className="text-black p-2 text-xl grid place-items-center w-full">
              { status === 'CONCLUIDA' ? t('pagamentorecebido') : t('aguardandopagamento') }
              {
                pixGerado ? null : 
                <>
                  {
                    qtddivisao === 'parcial' && 
                    <div className="relative">
                      <label className="absolute top-3 left-2" style={{ color: localStorage.getItem('corvalor') }}> {localStorage.getItem('moeda') === 'BRL' ? 'R$' : '$'} </label>
                      <input type="text" className="w-full p-3 pl-10 rounded shadow outline-none text-black" value={valordivisao} onChange={handlevalordivisao}/>
                    </div> 
                  }
                  <button className="bg-black text-white p-2 rounded w-full mt-3" onClick={()=> setQtddivisao('')}> {t('mudar')} </button>
                </>
              }
            </div>
          : 
          <>
            <div className="text-black"> {t('selecionevezes').toUpperCase()} </div>  
            <div className="w-full">
              <div className="grid place-items-center">
                <button className="w-full bg-black p-2 rounded m-1 text-white" onClick={()=> setQtddivisao('total')}> {t('total').toUpperCase()} </button>
                <button className="w-full bg-black p-2 rounded m-1 text-white" onClick={()=> setQtddivisao('parcial')}> {t('parcial').toUpperCase()} </button>
              </div>
            </div>
          </>
        }
        {
            pixQR ? (
                <QRCodeSVG
                    className="p-2 mb-5"
                    value={pixQR}
                    size={250}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"M"}
                    includeMargin={false}
                    imageSettings={{
                        src: 'https://futurium.com.br/wp-content/uploads/2021/06/logo-pix-icone-512.png',
                        x: undefined,
                        y: undefined,
                        height: 40,
                        width: 40,
                        excavate: true,
                    }}
                /> 
            ) : (
                <div className="h-20"></div>
            )
        }
        <div className="flex justify-between w-full">
          <button className="bg-red-500 p-5 rounded text-white" onClick={handlecancelar}> {t('cancelar').toUpperCase()} </button>
          {
            caixabaerto ? (
              pixGerado ? null : dadospix && qtddivisao && <button className="bg-black p-5 rounded text-white" onClick={gerarPix}> {t('gerarpix').toUpperCase()} </button>
            ) : (
              <button onClick={handleaddserv}> {t('chamar')} </button>
            )
          }
        </div>
      </motion.div>
    );
}

function Outro({ fechar, tipoComanda, numeroComanda }) {
  const { t } = useTranslation();

  const handleaddserv= async ()=>{
    const dados = {
      numerocomanda: numeroComanda,
      tipocomanda: tipoComanda,
      tipo: 'CONTA',
      mensagem: 'Outro metodo de pagamento!'
    }
    if(tipoComanda && numeroComanda){
      const res = await inserirServico(dados)
      if(res === 'ok'){
          const mensagem = t('garcomchamado')
          JanelaMensagem(mensagem, 'verde')
          fechar()
      }
    }
  }

  return (
    <motion.div
      className="p-4 bg-gray-100 rounded shadow w-full"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", stiffness: 100 }}
    >
        <div className="flex justify-between w-full">
          <button className="bg-red-500 p-5 rounded text-white" onClick={fechar}> {t('cancelar').toUpperCase()} </button>
          <button className="bg-black p-5 rounded text-white" onClick={handleaddserv}> {t('pagar').toUpperCase()} </button>
        </div>
    </motion.div>
  );
} 