import React, { useState, useEffect } from "react";
import { t } from 'i18next'
import { getFichaTecnica } from "../../api/chamados/get/produtos";
import './style.css'

export default function FichaTecnica({ item, retirados, setRetirados }) {
    const [fichaTecnica, setFichaTecnica] = useState([]);

    useEffect(()=>{
        handleFichaTecnica()
    }, [])

    const handleFichaTecnica= async ()=>{
        const idTamanho = item.produto.POSSUI_TAMANHO? item.tamanhoEscolhido?.ID : 0
        const dados = await getFichaTecnica(item.produto.ID_PRODUTO, idTamanho)
            setFichaTecnica(dados)
    }

    const handleSetListaRetirados = (index) => {
        const updatedFichaTecnica = fichaTecnica.map((item, idx) => 
            idx === index ? { ...item, retirado: !item.retirado } : item
        );
            setFichaTecnica(updatedFichaTecnica);
        const updatedRemover = updatedFichaTecnica.filter(item => item.retirado);
            setRetirados(updatedRemover);
    };

    return (
        <>
            <div className="grid place-items-center p-2" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}>
                <div> {t('removerIngrediente').toUpperCase()} </div>
            </div>
                {
                    fichaTecnica?.map((item, index) => (
                        <div key={index} className="border relative flex m-1 justify-between p-1 rounded">
                            <div className="grid place-items-center font-medium">{item.DESCRICAO}</div>
                            <div>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input 
                                        type="checkbox" 
                                        onChange={() => handleSetListaRetirados(index)} 
                                        checked={item.retirado} 
                                        className="sr-only peer" 
                                    />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                            </div>
                        </div>
                    ))
                }
        </>
    );
}