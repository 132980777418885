import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getGrupoOpcionais, getListOpcionais } from "../../api/chamados/get/produtos";
import { formatarValor } from "../../components/funcoes/funcoes";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import Decimal from "decimal.js";

export default function Opcionais({ item, grupos, setGrupos, currentIndex, newListSelect, setNewListSelect }) {
    const [quantidadeTotalGrupo, setQuantidadeTotalGrupo] = useState(0);
    const [inicialBuscado, setInicialBuscado] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if(item.mista === 'SIM'){
          gruposIniciais(0, item.tamanhoPizza.ID, true)
        } else {
          if (item.tamanhoProduto) {
            gruposIniciais(item.produto.ID_PRODUTO, item.tamanhoProduto.ID || 0, false);
          } else {
            gruposIniciais(item.produto.ID_PRODUTO, 0, false);
          }
        }
    }, [item]);

    useEffect(() => {
        calcularQuantidadeTotal();
    }, [newListSelect, grupos, currentIndex]);
    
    const gruposIniciais = async (produtoId, tamanhoId, isPizza) => {
            const gruposProduto = await getGrupoOpcionais(produtoId, tamanhoId, isPizza);       
            let uniqueGroupIndex = 0;
            const sortedGrupos = gruposProduto
                .sort((a, b) => a.ORDEM - b.ORDEM) 
                .map(grupo => {
                    const uniqueId = `${grupo.ID_GRUPO_OPCOES}-${item.mista === 'SIM' ? tamanhoId : produtoId}-${uniqueGroupIndex++}`;
                    return {
                        ...grupo,
                        produtoId,
                        produtoNome: item.mista === 'SIM' ? item.tamanhoPizza.TAMANHO : item.produto?.PRODUTO, 
                        grupoUnicoId: uniqueId,
                        inicial: true
                    };
                });
            setGrupos(sortedGrupos);
            setInicialBuscado(true)
    };

    useEffect(()=>{
      if(inicialBuscado && newListSelect.length > 0){
        gruposExtras()
      }
    }, [newListSelect, inicialBuscado])

    const gruposExtras = async () => {
      let gruposAdicionaisTemp = [...grupos];
      let uniqueGroupIndex = grupos.index;

      // Mantém um conjunto de IDs únicos de grupos já adicionados com base no grupoUnicoId
      const idsGruposExistentes = new Set(gruposAdicionaisTemp.map(grupo => grupo.grupoUnicoId));
  
      // Busca grupos adicionais
      
      const itensIniciais = newListSelect.filter(item => item.inicial);

      for (const item of itensIniciais) {
        const gruposAdicionais = await getGrupoOpcionais(item.ID, 0, false);

        if (gruposAdicionais.length > 0) {
            // Mapeia novos grupos para adicionar grupoUnicoId e filtra duplicações
            const novosGrupos = gruposAdicionais
                .map(grupo => {
                    const uniqueId = `${grupo.ID_GRUPO_OPCOES}-${item.ID}-${uniqueGroupIndex++}`;
                    return {
                        ...grupo,
                        produtoId: item.ID,
                        produtoNome: item.DESCRICAO,
                        grupoUnicoId: uniqueId,
                        inicial: false
                    };
                })
                .filter(grupo => !idsGruposExistentes.has(grupo.grupoUnicoId));

            // Adiciona novos grupos à lista de grupos
            gruposAdicionaisTemp = [...gruposAdicionaisTemp, ...novosGrupos];
        }
      }
      
      // Atualiza o estado com os grupos adicionados
      setGrupos(gruposAdicionaisTemp);
      setInicialBuscado(false)
    };
    
    const calcularQuantidadeTotal = () => {
        if (!grupos[currentIndex]) return;
                
        const grupoAtual = grupos[currentIndex];
        const total = newListSelect
            .filter(item => item.grupoUnicoId === grupoAtual.grupoUnicoId)
            .reduce((acc, item) => acc + (item.quantidade || 0), 0);

        setQuantidadeTotalGrupo(total);
    };

    return (
        <div>
            <GruposOpcionais
                item={item}
                grupos={grupos}
                currentIndex={currentIndex}
                quantidadeTotalGrupo={quantidadeTotalGrupo}
                t={t}
                newListSelect={newListSelect}
                setNewListSelect={setNewListSelect} 
            />
        </div>
    );
}

function GruposOpcionais({ item, grupos, currentIndex, quantidadeTotalGrupo, t, newListSelect, setNewListSelect }){
    const [total, setTotal] = useState(new Decimal(0));

    useEffect(() => {
      let baseTotal = new Decimal(item.total);
      const totalOptionalItems = newListSelect.reduce((sum, data) => {
        const valorVenda = new Decimal(data.VALOR_VENDA || 0);
        const quantidade = new Decimal(data.quantidade || 0);
        return sum.plus(valorVenda.times(quantidade));
      }, new Decimal(0));
      const newTotal = baseTotal.plus(totalOptionalItems);
      setTotal(newTotal);
    }, [newListSelect, item.total]);

    return(
        <>
            <div>
                <div className="text-sm grid place-items-center text-xl p-2 rounded-t" style={{ background: localStorage.getItem('corbarramenu'), color: localStorage.getItem('coriconemenu') }}>
                    {grupos[currentIndex]?.produtoNome}
                </div>
            </div>
            <div className="ml-2 mr-2 p-1 flex justify-between">
                <div className="font-bold">
                    {grupos[currentIndex]?.DESCRICAO}
                </div>
                <div className="border-2 border-red-500 text-red-500 p-1 rounded-lg"> {formatarValor(total.toFixed(2))} </div>
            </div>
            <div className="text-sm flex justify-between ml-3 mr-3 m-1">
                <div className="flex">
                    {
                        grupos[currentIndex]?.MINIMO === 0 ? null : (
                            <div className="border-2 border-blue-400 p-1 rounded-lg text-blue-400">
                                {t('minimo').toUpperCase()}: {grupos[currentIndex]?.MINIMO}
                            </div>
                        )
                    }                   
                    <div className={`border-2 border-blue-400 p-1 rounded-lg text-blue-400 ${grupos[currentIndex]?.MINIMO === 0 ? '' : 'ml-2'}`} >
                        {t('maximo').toUpperCase()}: {grupos[currentIndex]?.MAXIMO}
                    </div>
                </div>
                <div className="flex border-2 border-blue-400 text-blue-400 rounded-lg grid place-items-center p-1">
                    {t('atual').toUpperCase()}: {quantidadeTotalGrupo}
                </div>
            </div>
            <div className="overflow-y-auto dark:bg-black border-blue-400 pb-2 pt-2" style={{ maxHeight: '60vh' }}>
                <ListaOpcionais
                    item={item}
                    itemGrupoAdd={grupos[currentIndex]}
                    quantidadeTotalGrupo={quantidadeTotalGrupo}
                    Maximo={grupos[currentIndex]?.MAXIMO || 0}
                    newListSelect={newListSelect}
                    setNewListSelect={setNewListSelect}
                />
            </div>
        </>
    )
}

function ListaOpcionais({ item, itemGrupoAdd, quantidadeTotalGrupo, Maximo, newListSelect, setNewListSelect }) {
    const [listOP, setListOP] = useState([]);
    const [faltam, setFaltam] = useState(0);

    useEffect(() => {
        if (itemGrupoAdd) {
          handleListaOP()
        }
    }, [itemGrupoAdd]); 

    useEffect(() => {
      const totalSelected = listOP.reduce((total, item) => total + item.quantidade, 0);
        setFaltam(Maximo - totalSelected);
    }, [listOP, Maximo]);

    useEffect(() => {
      handleverificarnewlist();
    }, [newListSelect]);
    
    const handleListaOP = async () => {
        try {
            const opcionais = await getListOpcionais(itemGrupoAdd.ID_GRUPO_OPCOES, itemGrupoAdd.produtoId, item.mista === 'SIM' ? true : false);
            const updatedOpcionais = opcionais.map(item => ({
                ...item,
                quantidade: 0,
                grupoUnicoId: itemGrupoAdd.grupoUnicoId,
                descricaogrupo: itemGrupoAdd.DESCRICAO,
                inicial: itemGrupoAdd.inicial
            }));
            setListOP(updatedOpcionais);
            handleverificarnewlist(updatedOpcionais);
        } catch (error) {
            console.error('Error fetching opcionais:', error);
        }
    };

    const handleverificarnewlist = (updatedListOP = listOP) => {
        if (!newListSelect || newListSelect.length === 0) return;

        const updatedList = updatedListOP.map(opItem => {
            const matchingItem = newListSelect.find(nsItem => nsItem.ID === opItem.ID && nsItem.grupoUnicoId === opItem.grupoUnicoId);
            if (matchingItem) {
                return { ...opItem, quantidade: matchingItem.quantidade };
            }
            return opItem;
        });

        setListOP(updatedList);
    };

    const atualizarQuantidade = (index, incremento) => {
        setListOP(prevListOP => {
            const updatedListaOpcionais = [...prevListOP];
            const quantidadeAtual = new Decimal(updatedListaOpcionais[index].quantidade);
            const novaQuantidade = quantidadeAtual.plus(incremento).toNumber();
            updatedListaOpcionais[index].quantidade = Math.max(0, novaQuantidade);
            handleOPSelect(updatedListaOpcionais);
            return updatedListaOpcionais;
        });
    };

    const selecionarAdicional = (index) =>{
      setListOP(prevListOP => {
        // Verifica se o item clicado já está com quantidade 1
        const itemAlreadySelected = prevListOP[index]?.quantidade === 1;  
        // Atualiza listOP
        const updatedListOP = prevListOP.map((item, i) => ({
            ...item,
            quantidade: i === index ? (itemAlreadySelected ? 0 : 1) : item.quantidade
        }));  
        handleOPSelect(updatedListOP);  
        return updatedListOP;
      });
    }

    const selecionarAdicionalRadio = (index) => {
      setListOP(prevListOP => {
          // Verifica se o item clicado já está com quantidade 1
          const itemAlreadySelected = prevListOP[index]?.quantidade === 1;  
          // Atualiza listOP
          const updatedListOP = prevListOP.map((item, i) => ({
              ...item,
              quantidade: i === index ? (itemAlreadySelected ? 0 : 1) : 0
          }));  
          handleOPSelect(updatedListOP);  
          return updatedListOP;
      });
    };

    const handleOPSelect = (updatedListaOpcionais) => {
        const listaSelecionados = updatedListaOpcionais.filter(item => item.quantidade > 0);
        setNewListSelect(prevList => {
            const listaAtualizada = prevList
                .filter(item => item.grupoUnicoId !== itemGrupoAdd.grupoUnicoId)
                .concat(listaSelecionados);
            return listaAtualizada;
        });
    };

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return (
      <div>
        <div className='AdicionaisList'>
          {listOP.length > 0 && itemGrupoAdd ? (
            listOP.map((item, index) => (
              <div key={item.ID}>
                {itemGrupoAdd.PERMITIR_ITEM_REPETIDO === "SIM" ? (
                  <div className={`${boxShadowClass} cardAdicionais mb-3 mr-3 ml-3 flex rounded h-9 ${item.quantidade > 0 ? 'bg-green-200' : ''}`}>
                    <div className='w-3/4'>
                      <div className='font-medium'>{item.DESCRICAO}</div>
                      <div className='grid place-items-start'>
                        <div className='pr-1 pl-1 border-2 rounded-lg' style={{ color: localStorage.getItem('corvalor'), borderColor: localStorage.getItem('corvalor') }}>
                          {formatarValor(item.VALOR_VENDA)}
                        </div>
                      </div>
                    </div>
                    <div className='flex place-items-center w-1/4' style={{ minWidth: 150 }}>
                      <div className='w-[40%] grid place-items-center'>
                        <button className='btn' onClick={() => atualizarQuantidade(index, -1)} disabled={item.quantidade <= 0}>
                          <CiSquareMinus color='rgba(87, 87, 87, 1)' size={60} />
                        </button>
                      </div>
                      <div className='grid place-items-center text-2xl w-[20%]' style={{ color: 'rgba(87, 87, 87, 1)' }}>{item.quantidade}</div>
                      <div className='w-[40%] grid place-items-center'>
                        <button className='btn' onClick={() => atualizarQuantidade(index, 1)} disabled={faltam === 0}>
                          <CiSquarePlus color='rgba(87, 87, 87, 1)' size={60} />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : itemGrupoAdd.PERMITIR_ITEM_REPETIDO === "NAO" && itemGrupoAdd.MAXIMO > 1 ? (
                  <div className={`${boxShadowClass} cardAdicionais mb-3 mr-3 ml-3 flex justify-between rounded h-9 ${item.quantidade > 0 ? 'bg-green-200' : ''}`} onClick={() => selecionarAdicional(index)}>
                    <div className='descricaoAdicional'>
                      <div className='font-bold'>{item.DESCRICAO}</div>
                      <div className="flex" >
                        <div className='pr-1 pl-1 border-2 rounded-lg' style={{ color: localStorage.getItem('corvalor'), borderColor: localStorage.getItem('corvalor') }}>
                          {formatarValor(item.VALOR_VENDA)}
                        </div>
                      </div>
                    </div>
                    <div className='boxInputAdd'>
                      <input
                        type="checkbox"
                        disabled={faltam === 0 && item.quantidade !== 1}
                        checked={item.quantidade > 0}
                        onChange={() => selecionarAdicional(index)}
                      />
                      {item.quantidade > 0  
                          ? (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAqUlEQVR4nO2VQQrCMBBF36rewpNkETyTINKTWW/g2hO40xOYVUvpCCGQ1JlY3ORBNiHz/88EJtBQsgcGIACjcgWpnTWyXIET0GmTsdScxSRLMIp/2IlGlvmqtYzNIMYD961a5IAncNjCwGXE1QY3aUOMF/F032TgkqSl5CaDOPEReBWSV72BBx5fiJsNNPzXIPxg2L1LBwYZudZx3a+N69oP57L24TRImQB4HE1CkuADNAAAAABJRU5ErkJggg=="/>
                          )
                          : (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaklEQVR4nO2UQQqAMAwE93OKPyqogV6ifbYUcshFYQOKaAYCPUwZKDRAkjgmAA1AAbDZuZ2MOm8AQb8YQRl5CUZWRpYnIjUYqRlhqLfJjp9GJBiR1/14DUaUkUfbsLM9QbkYMW9nt3DycQ6q2hsLMO2nTAAAAABJRU5ErkJggg=="/>
                          )
                      }
                    </div>
                  </div>
                ) : itemGrupoAdd.MAXIMO === 1 ? (
                  <div className={`${boxShadowClass} cardAdicionais mb-3 mr-3 ml-3 flex justify-between rounded h-9 ${item.quantidade > 0 ? 'bg-green-200' : ''}`} onClick={() => selecionarAdicionalRadio(index)}>
                    <div className='descricaoAdicional'>
                      <div className='font-bold'>{item.DESCRICAO}</div>
                      <div className='flex'>
                        <div className='pr-1 pl-1 border-2 rounded-lg' style={{ color: localStorage.getItem('corvalor'), borderColor: localStorage.getItem('corvalor') }}>
                          {formatarValor(item.VALOR_VENDA)}
                        </div>
                      </div>
                    </div>
                    <div className='boxInputAdd'>
                      <input type='radio' name='radio' checked={item.quantidade > 0} readOnly />
                      {item.quantidade > 0  
                          ? (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVR4nN2VS04CQRRFT1pgBeqagImkRVgP4gRRE3ULpgOLEZiJUec6E3BMmZfcSkqggO7ECTd5SX3ep/q+T8OhowK0gQyYAj+Sqc5a0imEc+ADcMAnMADuJAOd2d07kOZxfATcyHgEVIFkg16iu7F0+xG9NXjn90AJOAWu5MhTZIG7ujOdB9lc70OLd264AOY62yR215SuD9KIOa+I85FeZc6XW5x7WSpISV/5Fkt8WwZVffq2l7sVmQEnQE17e9waMlVGIs5dTrmUrfl42hTgVeVnmBQIMJLtUH2yhoVKza/zBpgHVbiIBbBLcvLvgjwYboN1lKLxf1CUBUnuFgjQke1XLMktKdYKlOk3cAzUtffN9wcVDS7faM0cjZbKZqJGKxNBKiNrexRktuPl6cqoOGMH+kGQkjrUmuhZlbbQuiNaTOdRNj32QKKp6FRNtS3juh40ZW/fce3REJ9OlRH+cIY6c9LZSUsMZQ0uK7uXgCJb25nlKJrQw8Avi07u4ssmhaMAAAAASUVORK5CYII="/>
                          )
                          : (
                              <img className='h-8 w-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACr0lEQVR4nO2aS2tTQRiGn41NgrUlRUyXduMFvPwGb6XFSysqWq1bxU0pKmrEX1BdFStuxYpFpQs1ia2/QIpbtVE3XjZi1e50Jx+8C8GTy7nPCX3gQDgzZzJzvm9m3nkTWKNz6QNGgZtABXgH/AD+6LLPb1VmdUaAIo6QB84Ci8AvdfIqcAjYqo6u01XUPSu7BlT1zAIwDuTSGEABuAR8BZ4DxwN2xF7ECb2AL8BF3UuEg8BHYA7YEWG7O4FHwAdgmBixN3VXub8nxu/ZB9SBmTii0w+8Bu4B3cRPNzALLAGlqBodULivkDxl4L36EDoS1tA50uO8+hA4MnmlUxqR8IrMUtA5YxP7Ie5wH7gdZIm11Wk97rBBKTbkZ7OzfWIv7rFfg2krxS5rs3OVJ8Bkq0o5SYUod+yo2S1p1DQq49JOrlMFTjer8BI4hvucBGrNzhM/05LTPikAq0CvV+FRyemssAAc9iq4pUNRVigDU14FFW2EWeEI8NSrwM4AW8gO26Q+/mNFEz4rbAS+eRWY09FFdsgBvzt6ICudklr1TpnsFZlnWWGk0fLbMRviqFRlVlhslEFFebGJWZYRiMaeZkLMfFzXOdVK4J7JiAKuAWOtNpnPMpRdPup+aufcZNb+Y9xlHphop6KNdFmuuGsc8GMHod8nlhNy3tulR2b6oN8H7zjmb80C00EezMs4th00bW4Ar8IYIyXlpFn7aXFBgnZT2IYGNJhySpGoA5ujarCkNHsgVzyJiT2ndAodCa85M6PomCseF4NanabjNguHNBhzxXdFvGPPK5V8L7FhojMpOVOVF2uK1C8FCcCaZMdEWpZtTq54TbL6hRYFM8+2ywPo0tWne3ayuy6lvSqROuaS59wrL9ZObc/0B5rv//ypxj6/0fF0SnUbnifWIOP8BUVNi5Yl+rUtAAAAAElFTkSuQmCC"/>
                          ) 
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            ))
          ) : (
              <div className='grid place-items-center'>
                
              </div>
          )}
        </div>
      </div>
    );
  }
  