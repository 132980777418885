import React, { useEffect, useState } from "react"
import { getDescricaoProduto, getimgsproduto, getProdutos, imagemProduto } from "../../api/chamados/get/produtos";
import { formatarValor } from "../../components/funcoes/funcoes";
import './style.css'
import { addToCart } from "../../redux/cartSlice";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { RxLapTimer } from "react-icons/rx";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

export default function ListaProdutos({ busca, idsubgrupo, descricao, modalOpcionais, setModalOpcionais }) {
    const [produtos, setProdutos] = useState([]);
    const [modalImg, setModalImg] = useState(false);
    const [idproduto, setIdproduto] = useState('');
    const [isSelectingProduct, setIsSelectingProduct] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    

    useEffect(()=>{
        if(modalOpcionais === false){
            setIsSelectingProduct(false)
        }
    }, [modalOpcionais])

    useEffect(() => {
        handleProdutos();
    }, []);

    const handleProdutos = async () => {
        const res = await getProdutos(idsubgrupo);
        setProdutos(res);
    };

    const handleSelectProduto = async (value) => {
        if (isSelectingProduct) return; // Se já está selecionando, não faz nada

        setIsSelectingProduct(true); // Marca que a seleção está em andamento

        const item = {
          produto: value,
          total: value.VALOR_VENDA.toFixed(2),
          totalOP: 0,
          opcionais: [],
          tamanhoProduto: '',
          observacoes: '',
          mista: 'NAO'
        };

        await dispatch(addToCart(item));
        setModalOpcionais(true);
    };

    const handleAumentarImg = (id) => {
        setIdproduto(id);
        setModalImg(true);
    };

    const produtosFilter = produtos?.filter((item) =>
        item.PRODUTO && item.PRODUTO.toLowerCase().includes(busca.toLowerCase())
    );

    const boxShadowClass = localStorage.getItem('darkmode') === 'dark' ? 'boxShadowDarkMode' : 'boxshadowproduto';

    return (
        <>
            {produtosFilter?.length > 0 && (
                <div>
                    <div className='text-xl border-b-2 ml-2 mr-2 mt-14'>{descricao}</div>
                    {produtosFilter.map((item, index) => (
                        <div key={index} className={`${boxShadowClass} m-2 mt-5 p-2 flex justify-between`}>
                            <div className="w-[70%] grid align-end" onClick={() => handleSelectProduto(item)}>
                                <div className="flex justify-between">
                                    <div className="flex items-end font-bold"> {item.PRODUTO} </div>
                                    {
                                        item.TEMPO_PREPARO && 
                                        <div className="flex items-end font-bold"> 
                                            <RxLapTimer size={25} color={localStorage.getItem('corvalor')} />
                                            <div className="ml-1"> {item.TEMPO_PREPARO + ' ' + t('min')} </div>
                                        </div>
                                    }
                                </div>
                                    <Descricaoproduto itemID={item.ID_PRODUTO} />
                                <div className="grid place-items-start">
                                    <div className="border-2 pl-1 pr-1 rounded-lg" style={{ color: localStorage.getItem('corvalor'), borderColor: localStorage.getItem('corvalor') }}> {formatarValor(item.VALOR_VENDA)} </div>
                                </div>
                            </div>
                            <div className='w-[30%] grid place-items-end'>
                                <Imgproduto itemID={item.ID_PRODUTO} handleAumentarImg={handleAumentarImg} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {modalImg && <ModalImg itemID={idproduto} modalImg={modalImg} setModalImg={setModalImg} onModalClose={() => setIsSelectingProduct(false)} />}
        </>
    );
}

function Imgproduto({ itemID, handleAumentarImg }){
    const [img, setImg] = useState('');

    useEffect(()=>{
        handleGetImg()
    }, [itemID])

    const handleGetImg = async (id) => {
        try {
            const res = await imagemProduto(itemID);
                setImg(res)
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    return(
        <div className="h-24 w-24">
            {img && <button onClick={()=> handleAumentarImg(itemID)}>
                        <img src={img} alt="Produto" className='rounded h-24 w-24'/>
                    </button>
            }
        </div>
    )
}

function Descricaoproduto({ itemID }){
    const [descricao, setDescricao] = useState('');

    useEffect(()=>{
        handleDescricaoProduto()
    }, [itemID])

    const handleDescricaoProduto= async ()=>{
        const res = await getDescricaoProduto(itemID)
            setDescricao(res)
    }
    return(
        <>
            { descricao? (
                <div className="h-12 overflow-auto">
                    <div className="ml-2 text-sm"> {descricao?.toLowerCase()} </div>
                </div>
            ) : null}
        </>
        
    )
}

function ModalImg({ itemID, modalImg, setModalImg }) {
    const [imgs, setImgs] = useState([]);
    const [isClosing, setIsClosing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        handleImgsProduto();
    }, []);

    const handleImgsProduto = async () => {
        const res = await getimgsproduto(itemID);
            setImgs(res);
    };

    const handleFechar = () => {
        setIsClosing(true);
        setTimeout(() => {
            setModalImg(false);
        }, 600);
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.1, borderRadius: '50%' },
        visible: { opacity: 1, scale: 1, borderRadius: '0%' },
        exit: {
            opacity: 0,
            scale: 0.4,
            borderRadius: '50%',
            transition: { duration: 0.4, ease: 'easeOut' },
        },
    };

    useEffect(() => {
        document.body.style.overflow = modalImg ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalImg]);

    if (!modalImg) return null;

    return (
        <AnimatePresence>
            {!isClosing && modalImg && (
                <>
                    <motion.div
                        className="overlay"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={overlayVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    />
                    <motion.div
                        className="modal-container"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={modalVariants}
                        transition={{ duration: 0.4, ease: 'easeOut' }}
                    >
                        <div className="modal w-[100%] h-[100%] relative rounded">
                            {imgs?.length > 0 ? (
                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 50, // Degree of rotation of the slides
                                        stretch: 0, // Stretch factor of the slides
                                        depth: 100, // Depth effect
                                        modifier: 1, // Scale modifier for the slides
                                        slideShadows: true, // Show slide shadows
                                    }}
                                    pagination={{ clickable: true }}
                                    modules={[EffectCoverflow, Pagination]}
                                    className="mySwiper"
                                    style={{ height: '100%', width: '100%' }} // Adjust height and width as needed
                                >
                                    {imgs.map((img, index) => (
                                        <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img
                                                src={img}
                                                alt={`Imagem ${index + 1}`}
                                                className="max-w-[70%] max-h-[70%]"
                                                style={{ borderRadius: '10px' }} // Adjust the style as needed
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            ) : (
                                <div className="grid place-items-center h-full text-white text-6xl"> {t('semimagem').toUpperCase()} </div>
                            )}
                        </div>
                        <button className="fixed top-5 right-5 text-white" style={{ zIndex: 9999 }} onClick={handleFechar}> 
                            <IoClose size={50}/>
                        </button>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
}